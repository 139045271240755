import React from 'react';
import ReactPlayer from 'react-player';

interface VideoProps {
  url: string;
}

const VideoTestPage = ({ url }: VideoProps) => {
  return <ReactPlayer controls url={url} />;
};

export default VideoTestPage;
