import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  addToProducts,
  clearProducts,
  formatDate,
  getFromLocalStorage,
  setToLocalStorage,
} from '../../global/helpers';
import {
  DeliveryType,
  OrderTableData,
  PaymentType,
  UserRole,
} from '../OrdersPage/types';
import { selectors } from '../OrdersPage/reducer';
import { selectors as selectorsOrder } from '../OrderPage/reducer';
import { selectors as selectorsLogin } from '../LoginPage/reducer';
import { selectors as selectorsReport } from '../ReportPage/reducer';
import * as actions from '../OrdersPage/actions';
import * as actionsReport from '../ReportPage/actions';
import useRefresh from '../../global/hooks/useRefresh';
import * as actionsOrder from '../OrderPage/actions';
import faviconSvg from '../../global/media/favicon.svg';
import Loader from '../../components/Loader';
import * as Styled from '../OrdersPage/OrdersPage.styles';
import ManagerCollector from '../OrdersPage/components/ManagerCollector';
import Button from '../../components/Button';
import questionIcon from '../../global/media/question.png';
import InfoReportByDay from '../../components/InfoReportByDay';
import { amountOfOrders, getDay } from '../OrdersPage/helpers';
import searchIcon from '../../global/media/search.svg';
import OrdersTable from '../OrdersPage/components/OrdersTable';
import OrderSearchModal from '../../components/OrderSearchModal';
import OrderDetailedModal from '../../components/OrderDetailedModal';
import OrderCancelModal from '../../components/OrderCancelModal';
import ActivePharmacistBlock from '../../components/ActivePharmacistBlock';
import NewOrderNotification from '../../components/NewOrderNotification';
import VideoTestPage from '../../components/VideoTestPage';
import { selectors as selectorsMagnum } from '../../components/ActivePharmacistBlock/reducer';
import * as actionMagnum from '../../components/ActivePharmacistBlock/actions';
import { StatusType } from '../../global/types';

const TestOrdersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';
  const userRole = getFromLocalStorage('role');
  const [isShowSearchModal, setShowSearchModal] = useState(false);
  const [cancelOrder, setCancelOrder] = useState<null | OrderTableData>(null);
  const [statusOrders, setStatusOrders] = useState<string>('');
  const [paymentOrders, setPaymentOrders] = useState<string | boolean>('');
  const [paymentTypeOrders, setPaymentTypeOrders] = useState<string>('');
  const [deliveryOrders, setDeliveryOrders] = useState<string>('');
  const [sourceOrders, setSourceOrders] = useState<string>('');
  const [confirmOrderOpen, setConfirmOrderOpen] = useState<boolean>(false);
  const [pulseActive, setPulseActive] = useState<boolean>(false);
  const [isPharmacistOnOrderShift, setPharmacistOnOrderShift] =
    useState<boolean>(false);
  const [isPharmacistOnOrderOpen, setPharmacistOnOrderOpen] =
    useState<boolean>(false);
  const [isShownPharmacistToolTip, setShownPharmacistToolTip] =
    useState<boolean>(false);
  const [pharmacistOnOrder, setPharmacistOnOrder] = useState<{
    name: string;
    phone: string;
  } | null>(null);

  const data = useSelector(selectors.ordersPageData);
  const dataLoading = useSelector(selectors.ordersPageLoading);
  const orderId = useSelector(selectors.orderId);
  const selectedActivePharmacist = useSelector(
    selectorsOrder.selectedActivePharmacist
  );
  const dataUser = useSelector(selectorsLogin.userData);
  const newOrdersMuted = useSelector(selectors.notMutedOrders);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const sourcesData = useSelector(selectors.sourcesData);

  const dataToday = useSelector(selectorsReport.ordersToday);
  const dataYesterday = useSelector(selectorsReport.ordersYesterday);

  const magnumUsers = useSelector(selectorsMagnum.activeUsersData);

  const formatDateForRequest = (yesterday?: boolean) => {
    return !yesterday
      ? formatDate().split('.').reverse().join('-')
      : formatDate(true).split('.').reverse().join('-');
  };

  useEffect(() => {
    addToProducts();
    setToLocalStorage('selectedOrderId', '');
    setToLocalStorage('selectedOrder', {});
  }, []);

  useEffect(() => {
    dispatch(actions.getNotMutedOrders.request({ testOrders: true }));
    dispatch(
      actions.getOrders.request({
        testOrders: true,
      })
    );
    dispatch(
      actionsReport.getReportByDate.request({
        date: formatDateForRequest(),
        dateYest: formatDateForRequest(true),
        testOrders: true,
      })
    );
    clearProducts();
  }, [
    dispatch,
    role,
    statusOrders,
    paymentOrders,
    sourceOrders,
    deliveryOrders,
    paymentTypeOrders,
  ]);

  useRefresh(() => {
    dispatch(actions.getNotMutedOrders.request({ testOrders: true }));
    dispatch(
      actions.getOrders.request({
        testOrders: true,
      })
    );
    dispatch(
      actionsReport.getReportByDate.request({
        date: formatDateForRequest(),
        dateYest: formatDateForRequest(true),
        testOrders: true,
      })
    );
  }, [dispatch, statusOrders, paymentOrders, sourceOrders]);

  useEffect(() => {
    if (
      userRole === UserRole.PHARMACIST ||
      userRole === UserRole.MANAGER ||
      userRole === UserRole.PHARMACY_MANAGER
    ) {
      if (dataUser && !magnumUsers) {
        dispatch(
          actionMagnum.getAllUsers.request({ sourceCode: dataUser.sourceCode })
        );
      }
    }
  }, [dataUser]);

  const handleSetOrdersStatus = (status: string) => {
    setStatusOrders(status);
  };
  const handleSetSourceStatus = (source: string) => {
    setSourceOrders(source);
  };

  const handleSetPaymentStatus = (payment: boolean | string) => {
    setPaymentOrders(payment);
  };

  const handleSetPaymentTypeStatus = (payment: string) => {
    setPaymentTypeOrders(payment);
  };

  const handleSetDeliveryStatus = (delivery: string) => {
    setDeliveryOrders(delivery);
  };

  const handleOrderModalClose = () => {
    dispatch(actions.clearOrderId());
    dispatch(actions.clearSelectedOrder());
    dispatch(actions.getOrders.request({ role, status: '' }));
    setOpenDetail(false);
    setShowSearchModal(true);
  };

  const handleOrderCancel = (data: OrderTableData) => {
    setCancelOrder(data);
    setShowSearchModal(false);
  };

  const handleDeclineOrder = (data: OrderTableData) => {
    setCancelOrder(null);
  };

  const handleSubmitOrder = (message: string, id: string) => {
    setCancelOrder(null);
    dispatch(
      actions.cancelOrder.request({
        message,
        id,
        postEffect: () =>
          dispatch(
            actions.setSelectedOrder.request({
              role,
              id: orderId,
              testOrders: true,
            })
          ),
      })
    );
  };

  useEffect(() => {
    if (
      newOrdersMuted &&
      newOrdersMuted?.count > 0 &&
      (userRole === UserRole.PHARMACIST ||
        UserRole.MANAGER ||
        userRole === UserRole.PHARMACY_MANAGER)
    ) {
      setConfirmOrderOpen(true);
    } else setConfirmOrderOpen(false);
  }, [newOrdersMuted]);

  useEffect(() => {
    const needsVideoOrders = [];

    if (data) {
      data.forEach((item) => {
        const isDeliveryOrder = data?.find(
          (el) => el.deliveryType === DeliveryType.DELIVERY
        );

        const isSelfKaspiOrder = data?.find(
          (el) =>
            el.deliveryType === DeliveryType.SELF &&
            el.paymentType === PaymentType.KASPI_PAY
        );

        if (
          item.deliveryType === DeliveryType.DELIVERY &&
          item.statusPharmacy !== StatusType.COMPLETED &&
          isDeliveryOrder?.statusPharmacy !== StatusType.COMPLETED &&
          isSelfKaspiOrder?.statusPharmacy !== StatusType.COMPLETED
        ) {
          needsVideoOrders.push(data);
        }
      });
    }
  }, [data]);

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const handleGenerateTestOrders = () => {
    dispatch(
      actions.generateTestOrders.request({
        phone: dataUser.phone,
        source: dataUser.sourceCode,
        postEffect: () => {
          dispatch(
            actions.getOrders.request({
              testOrders: true,
            })
          );
          dispatch(actions.getNotMutedOrders.request({ testOrders: true }));
          setPulseActive(false);
        },
      })
    );
  };

  const handleSetOrderPharmacist = (collector: {
    name: string;
    phone: string;
  }) => {
    dispatch(actionsOrder.setSelectedActivePharmacistOnOrder(collector));
    setPharmacistOnOrder(collector);
  };

  const handleOpenPharmacistOnOrderShift = (isOpen: boolean) => {
    setPharmacistOnOrderShift(isOpen);
  };

  const handleOpenPharmacistOnOrder = (isOpen: boolean) => {
    setPharmacistOnOrderOpen(isOpen);
  };

  const handleEmptyActiveUserClick = () => {
    handleOpenPharmacistOnOrder(false);
    handleOpenPharmacistOnOrderShift(true);
  };

  const handleSaveOrderPharmacist = () => {
    selectedActivePharmacist &&
      orderId &&
      history.push(`/test-orders/${orderId}`);
  };

  const handleInstruction = () => {
    window.location.href = '/instruction.html';
  };

  return (
    <>
      {!confirmOrderOpen && (
        <Helmet>
          <link rel="icon" type="image/png" href={faviconSvg} />
        </Helmet>
      )}
      {!data && dataLoading && <Loader />}
      <Styled.OrdersPageContainer
        onClick={handleClickStart}
        isOperator={
          userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR
        }
      >
        <Styled.NumberCallCenter>
          {t('OrderPage.Collection.NUMBER_CALL_CENTER')}
        </Styled.NumberCallCenter>
        <Styled.TestAttention>{t('OrdersPage.TEST_PAGE')}</Styled.TestAttention>
        <Styled.TestAttentionIns>
          {t('OrdersPage.TEST_PAGE_INSTRUCTION')}
          <span onClick={handleInstruction}>{t('OrdersPage.INSTRUCTION')}</span>
        </Styled.TestAttentionIns>

        {(userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) && <ManagerCollector />}
        {(userRole === UserRole.PHARMACIST ||
          ((userRole === UserRole.MANAGER ||
            userRole === UserRole.PHARMACY_MANAGER) &&
            dataUser?.isCollector &&
            sourcesData?.length !== 0)) && (
          <Styled.PharmacistContainer>
            {' '}
            {userRole === UserRole.PHARMACIST && dataUser?.disabled && (
              <div className="error-message">
                {t('OrderPage.Collection.PHARMACY_DISABLED')}
              </div>
            )}
            <Styled.PharmacistButton>
              <Button
                variant="dark"
                text={t('OrdersPage.ActivePharmacistBlock.TITLE_ON_SHIFT')}
                onClick={() => setPharmacistOnOrderShift(true)}
              />
            </Styled.PharmacistButton>
            <div
              onMouseEnter={() => setShownPharmacistToolTip(true)}
              onMouseLeave={() => setShownPharmacistToolTip(false)}
            >
              <img src={questionIcon} alt="pharmacist-tooltip" />
            </div>
            {isShownPharmacistToolTip && (
              <div className="pharmacist-tooltip">
                {t('OrdersPage.ActivePharmacistBlock.TOOLTIP_FIRST')} <br />
                <br />
                {t('OrdersPage.ActivePharmacistBlock.TOOLTIP_SECOND')}
              </div>
            )}
          </Styled.PharmacistContainer>
        )}
        <Styled.InfoByDay>
          <Styled.ReportByDaysContainer id="report">
            <InfoReportByDay
              day={`${getDay(true)}, ${formatDate(true)}`}
              amountsInPlace={
                amountOfOrders(dataYesterday).closedAmountOfOrdersInPlace
              }
              amountsOnline={
                amountOfOrders(dataYesterday).closedAmountOfOrdersOnline
              }
              ordersInPlace={amountOfOrders(dataYesterday).closedOrdersInPlace}
              ordersOnline={amountOfOrders(dataYesterday).closedOrdersOnline}
            />
            <InfoReportByDay
              day={` ${getDay()}, ${formatDate()}`}
              amountsInPlace={
                amountOfOrders(dataToday).closedAmountOfOrdersInPlace
              }
              amountsOnline={
                amountOfOrders(dataToday).closedAmountOfOrdersOnline
              }
              ordersInPlace={amountOfOrders(dataToday).closedOrdersInPlace}
              ordersOnline={amountOfOrders(dataToday).closedOrdersOnline}
            />
          </Styled.ReportByDaysContainer>
        </Styled.InfoByDay>
        <div className="test-video-margin">
          <VideoTestPage url="https://www.youtube.com/watch?v=p-Hxj9ng7wk" />
        </div>
        <Styled.OrdersPageHeaderContainer>
          <Styled.TextContainer>
            <Styled.OrdersPageTitle>
              {t('OrdersPage.TITLE')}
            </Styled.OrdersPageTitle>
            <Styled.OrdersCreateText
              onClick={handleGenerateTestOrders}
              id="generate_orders"
            >
              <div className={`${pulseActive && 'pulse-animation'}`}>
                {t('OrdersPage.CREATE_ORDERS_BTN_TEXT')}
              </div>
            </Styled.OrdersCreateText>
          </Styled.TextContainer>
          <div id="search">
            <Button
              onClick={() => setShowSearchModal(true)}
              text={t('OrdersPage.BTN_TEXT')}
              img={searchIcon}
              variant="dark"
            />
          </div>
        </Styled.OrdersPageHeaderContainer>
        <Styled.OrdersPageTableContainer>
          <OrdersTable
            data={data}
            setStatus={handleSetOrdersStatus}
            setPayment={handleSetPaymentStatus}
            setDelivery={handleSetDeliveryStatus}
            setPaymentType={handleSetPaymentTypeStatus}
            setSource={
              (userRole === UserRole.MANAGER ||
                userRole === UserRole.OPERATOR ||
                userRole === UserRole.HEAD_OPERATOR ||
                userRole === UserRole.PHARMACY_MANAGER ||
                userRole === UserRole.ADMIN) &&
              handleSetSourceStatus
            }
            onOrderClick={handleOpenPharmacistOnOrder}
            paymentOrders={paymentOrders}
            onSetOrderPharmacist={handleSetOrderPharmacist}
          />
        </Styled.OrdersPageTableContainer>
      </Styled.OrdersPageContainer>
      {isShowSearchModal && (
        <OrderSearchModal
          openDetail={() => setOpenDetail(true)}
          onClose={() => setShowSearchModal(false)}
        />
      )}
      {openDetail && (
        <OrderDetailedModal
          onClose={handleOrderModalClose}
          onOrderCancel={handleOrderCancel}
        />
      )}
      {cancelOrder && (
        <OrderCancelModal
          onDecline={handleDeclineOrder}
          onSubmit={handleSubmitOrder}
          data={cancelOrder}
        />
      )}
      {isPharmacistOnOrderShift &&
        (userRole === UserRole.PHARMACIST ||
          userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) && (
          <ActivePharmacistBlock
            variant="isPharmacistOnShift"
            onModalClose={handleOpenPharmacistOnOrderShift}
          />
        )}
      {isPharmacistOnOrderOpen && (
        <ActivePharmacistBlock
          variant="isPharmacistOnOrder"
          onModalClose={handleOpenPharmacistOnOrder}
          onSaveOrderPharmacist={handleSaveOrderPharmacist}
          onSetOrderPharmacist={handleSetOrderPharmacist}
          pharmacistOnOrder={pharmacistOnOrder}
          orderId={orderId}
          onEmptyActiveUsersClick={handleEmptyActiveUserClick}
        />
      )}
      {(userRole === UserRole.PHARMACIST ||
        userRole === UserRole.MANAGER ||
        userRole === UserRole.PHARMACY_MANAGER) &&
        confirmOrderOpen &&
        !dataLoading && (
          <NewOrderNotification
            onClose={() => setConfirmOrderOpen(false)}
            newOrders={newOrdersMuted}
          />
        )}
    </>
  );
};

export default TestOrdersPage;
