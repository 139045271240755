import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const AdminPageContainer = styled('div')`
  max-width: 1216px;
  margin: auto;
  padding: 34px 0;

  .flex-container {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  .filter-container-cities {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const ButtonsBlock = styled('div')`
  display: flex;

  .add-network-btn {
    margin-right: 16px;
  }
`;
export const SearchContainer = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
`;

export const ButtonSearch = styled('div')`
  cursor: pointer;
  background: #0a77c7;
  border-radius: 20px;
  padding: 10px 24px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transition: 200ms;
    background-color: #003e6e;
  }
`;

export const ContainerInput = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 50px;
  border-radius: 20px;
  background: #f7f7f7;
  padding-left: 15px;
  padding-right: 15px;

  .search-input {
    background: transparent;
    outline: none;
    border: none;
    width: 280px;
  }
  .clear-input {
    cursor: pointer;
  }
`;

export const ContainerRadioBox = styled('div')`
  display: flex;
  gap: 10px;
  margin-top: 5px;
  align-items: center;
`;

export const ContainerToggle = styled('div')`
  display: flex;
  gap: 10px;
  margin-top: 5px;
  align-items: center;
  border: solid 1px #0a77c7;
  padding: 10px;
  width: fit-content;
  border-radius: 8px;
`;

export const Source = styled('div')`
  margin-top: 34px;
  padding: 20px;
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;
`;

export const NameSourceBLock = styled('div')`
  display: flex;

  .name {
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.mainLightBlue};

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .network-name-block {
    font-weight: 500;
    padding: 2px 0 0 16px;
    font-size: 16px !important;
    display: flex;
    color: ${baseTheme.colors.mainLightBlue};
    .network {
      font-weight: 400;
      color: ${baseTheme.colors.secondary};
      padding-right: 4px;
      text-decoration: none;
    }

    .name-network {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

export const FilterTags = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin-top: 10px;

  .reset-tag {
    cursor: pointer;
    margin-left: 16px;
    font-weight: 600;
    color: ${baseTheme.colors.error};
    font-size: 14px;
  }

  .tags-block {
    max-width: none !important;
    display: flex;
    align-items: center;

    .set-tag {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 18px;
      overflow-y: hidden;
      width: 80px;

      img {
        height: 10px !important;
      }

      .text {
        margin-right: 12px;
        color: ${baseTheme.colors.secondary};
      }
    }

    .filter-info {
      margin-left: 8px;
      display: flex;
      align-items: center;
      width: 85%;
      img {
        cursor: pointer;
        height: 20px;
        margin-bottom: -5px;
      }
      .filter-by {
        color: ${baseTheme.colors.secondary} !important;
      }

      span {
        color: ${baseTheme.colors.mainLightBlue};
      }
    }
  }
`;
