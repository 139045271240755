import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SourceData } from '../../../../types';
import * as Styled from './SourceRow.styles';
import CustomToggle from '../../../../../../components/CustomToggle';
import * as actions from '../../../../actions';

interface SourceRowParam {
  data: SourceData;
}

const SourceRow = ({ data }: SourceRowParam) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<boolean>(data.activated);
  const [visible, setVisible] = useState<boolean>(data.visible);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [comments, setComments] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComments(e.target.value);
  };

  useEffect(() => {
    setChecked(data.activated);
  }, [data.activated]);

  useEffect(() => {
    error && setError(false);
  }, [comments]);

  useEffect(() => {
    if (checked === data.activated && visible === data.visible) {
      setDisableButton(true);
    } else setDisableButton(false);
  }, [checked, visible, data]);

  const handleChangeActivationProducts = () => {
    if (checked !== data?.activated) {
      if (checked) {
        dispatch(
          actions.activateSource.request({
            sourceCode: data.code,
            postEffect: () => dispatch(actions.getSources.request()),
          })
        );
      } else if (comments.trim() === '') {
        setError(true);
      } else {
        dispatch(
          actions.deactivateSource.request({
            sourceCode: data.code,
            message: comments.trim(),
            postEffect: () => dispatch(actions.getSources.request()),
          })
        );
      }
    }

    if (visible !== data?.visible) {
      if (visible) {
        dispatch(
          actions.setVisibleSource.request({
            sourceCode: data.code,
            postEffect: () => dispatch(actions.getSources.request()),
          })
        );
      } else {
        dispatch(
          actions.setInvisibleSource.request({
            sourceCode: data.code,
            postEffect: () => dispatch(actions.getSources.request()),
          })
        );
      }
    }
  };

  return (
    <Styled.SourceRow>
      <td className="table-cell">1</td>
      <td className="table-cell">{data.name}</td>
      <td className="table-cell">{data.city}</td>
      <td className="table-cell">{data.address}</td>
      <td className="table-cell">{data.openingHours}</td>
      <td className="table-cell">{data.lastSync}</td>
      <td className="table-cell">
        <div>
          <div className="toggle-block">
            <CustomToggle
              onChange={() => {
                setChecked(!checked);
                setDisableButton(false);
              }}
              id={data.name}
              checked={checked}
              variant="standart"
            />
            <div className="disableUpdatedAt"> {data.disableUpdatedAt} </div>
          </div>

          <div className="toggle-block-visible ">
            <CustomToggle
              onChange={() => {
                setVisible(!visible);
                setDisableButton(false);
              }}
              id={data.address}
              checked={visible}
              variant="standart"
            />
            <div className="disableUpdatedAt">
              {visible ? t('SourcesPage.VISIBLE') : t('SourcesPage.INVISIBLE')}{' '}
            </div>
          </div>
          {!disableButton && !checked && (
            <input
              placeholder={t('SourcesPage.YOUR_COMMENT')}
              value={comments}
              onChange={handleInputChange}
              type="text"
              className={error ? 'input-comments-error' : 'input-comments'}
              maxLength={30}
            />
          )}
          {disableButton && !checked && data.comments && (
            <p className="comment">{data.comments}</p>
          )}
          {error && (
            <p className="comment-error">{t('SourcesPage.ERROR_COMMENTS')}</p>
          )}

          <div className="disableUpdatedBy">
            {checked ? 'Включена:' : 'Отключена:'}{' '}
            <span>+{data.disableUpdatedBy || 'не указано'}</span>
          </div>

          <div
            className={`save-btn ${disableButton && 'disable'}`}
            onClick={handleChangeActivationProducts}
          >
            {t('SourcesPage.SAVE')}
          </div>
          {data && data.hasSyncError && (
            <div className="container-error">
              {t('SourcesPage.ERROR')}
              <div className="error"> {t('SourcesPage.DETAILS_ERROR')}</div>
            </div>
          )}
        </div>
      </td>
    </Styled.SourceRow>
  );
};

export default SourceRow;
