import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Styled from './MainPage.styles';
import Footer from '../../components/Footer';
import OrdersPage from '../OrdersPage';
import ProductsPage from '../ProductsPage';
import withAuth from '../../global/hocs/withAuth';
import Header from '../../components/Header';
import NotificationsList from '../../components/NotificationsList';
import EditPage from '../EditPage';
import OrderPage from '../OrderPage';
import OrderHistoryPage from '../OrderHistoryPage';
import { getFromLocalStorage, setToLocalStorage } from '../../global/helpers';
import { UserRole } from '../OrdersPage/types';
import SourcePage from '../SourcePage';
import SourcesPage from '../SourcesPage';
import PromoCodePage from '../PromoCodePage';
import NetworkPage from '../NetworkPage';
import OperatorsPharmacistPage from '../OperatorsPharmacistPage';
import CreateSourceNetworkPage from '../CreateSourceNetworkPage';
import ReportPage from '../ReportPage';
import TestOrdersPage from '../TestOrdersPage';
import EmptyUserPage from '../EmptyUserPage/EmptyUserPage';
import WebStockPage from '../WebStockPage';
import PharmacyTagsPage from '../PharmacyTagsPage';
import AnalogsPage from '../AnalogsPage';
import { ErrorMessageData } from '../../global/types';
import ErrorAlertModal from '../../components/ErrorAlertModal';
import PharmacistPromoCodePage from '../PharmacistPromoCodePage';
import SettingsPage from '../SettingsPage/SettingsPage';

const MainPage = () => {
  const role = getFromLocalStorage('role');

  const [errorModalData, setErrorModalData] = useState<ErrorMessageData | null>(
    null
  );
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setErrorModalData(null);
      setToLocalStorage('requestErrorData', null);
      window.dispatchEvent(new Event('storage'));
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const onConnectionLost = () => {
      setErrorModalData({
        url: '',
        error_message: t('ERROR_MESSAGE_MODAL.POOR_CONNECTION'),
        time: '',
        status: '',
        x_request_id: '',
        x_trace_id: '',
        code: null,
      });
    };

    const checkUserData = (event: StorageEvent) => {
      if (document?.hasFocus() && !event.key && event.key !== 'testKey') {
        const errorModalInfo = getFromLocalStorage('requestErrorData');
        if (
          errorModalInfo &&
          errorModalInfo?.error_message !== 'claim made by yandex'
        ) {
          setErrorModalData(errorModalInfo);
        } else {
          setErrorModalData(null);
        }
      }
    };
    if (
      role === UserRole.ADMIN ||
      role === UserRole.CONTENT ||
      role === UserRole.HEAD_OPERATOR ||
      role === UserRole.OPERATOR
    ) {
      window.addEventListener('storage', checkUserData);
      window.dispatchEvent(new Event('storage'));
      window.addEventListener('offline', onConnectionLost);

      return () => {
        window.removeEventListener('storage', checkUserData);
        window.removeEventListener('offline', onConnectionLost);
      };
    }
  }, []);

  return (
    <div>
      <Header />
      <Styled.MainPageContentContainer>
        {errorModalData && <ErrorAlertModal errorModalInfo={errorModalData} />}
        <Switch>
          {role === UserRole.PHARMACIST && (
            <Route
              exact
              path="/promo-code"
              component={PharmacistPromoCodePage}
            />
          )}
          {(role === UserRole.ADMIN ||
            role === UserRole.CONTENT ||
            role === UserRole.HEAD_OPERATOR ||
            role === UserRole.OPERATOR) && (
            <Route exact path="/analogs" component={AnalogsPage} />
          )}
          {role === UserRole.CUSTOMER && (
            <Route exact path="/" component={EmptyUserPage} />
          )}
          {role !== UserRole.CUSTOMER && role !== UserRole.CONTENT && (
            <Route exact path="/orders" component={OrdersPage} />
          )}
          {role !== UserRole.CUSTOMER && role !== UserRole.CONTENT && (
            <Route
              exact
              path="/orders/:orderId/history"
              component={OrderHistoryPage}
            />
          )}

          {(role === UserRole.PHARMACIST ||
            role === UserRole.MANAGER ||
            role === UserRole.PHARMACY_MANAGER) && (
            <Route exact path="/web-stock" component={WebStockPage} />
          )}

          {role === UserRole.ADMIN && (
            <>
              <Route exact path="/sources" component={SourcesPage} />
              <Route
                exact
                path="/operators"
                component={OperatorsPharmacistPage}
              />
              <Route exact path="/create" component={CreateSourceNetworkPage} />
              <Route exact path="/sources/:sourceCode" component={SourcePage} />
              <Route exact path="/pharmacy-tags" component={PharmacyTagsPage} />
              <Route exact path="/settings" component={SettingsPage} />
              <Route
                exact
                path="/networks/:networkCode"
                component={NetworkPage}
              />
              <Route exact path="/report" component={ReportPage} />
              <Route exact path="/orders/:orderId" component={OrderPage} />
              <Route exact path="/products" component={ProductsPage} />
              <Route exact path="/edit" component={EditPage} />
              <Route exact path="/promo-code" component={PromoCodePage} />
            </>
          )}
          {role === UserRole.HEAD_OPERATOR && (
            <>
              <Route exact path="/sources" component={SourcesPage} />
              <Route exact path="/sources/:sourceCode" component={SourcePage} />
              <Route exact path="/promo-code" component={PromoCodePage} />
            </>
          )}
          {(role === UserRole.MANAGER ||
            role === UserRole.OPERATOR ||
            role === UserRole.HEAD_OPERATOR ||
            role === UserRole.PHARMACY_MANAGER) && (
            <Route exact path="/report" component={ReportPage} />
          )}
          {(role === UserRole.MANAGER ||
            role === UserRole.PHARMACIST ||
            role === UserRole.PHARMACY_MANAGER) && (
            <Route exact path="/test-orders" component={TestOrdersPage} />
          )}
          {role === UserRole.MANAGER ||
            (role === UserRole.PHARMACY_MANAGER && (
              <Route
                exact
                path="/pharmacist"
                component={OperatorsPharmacistPage}
              />
            ))}
          {(role === UserRole.MANAGER ||
            role === UserRole.CONTENT ||
            role === UserRole.HEAD_OPERATOR ||
            role === UserRole.PHARMACY_MANAGER ||
            role === UserRole.OPERATOR) && (
            <Route exact path="/products" component={ProductsPage} />
          )}
          {(role === UserRole.MANAGER ||
            role === UserRole.CONTENT ||
            role === UserRole.HEAD_OPERATOR ||
            role === UserRole.PHARMACY_MANAGER ||
            role === UserRole.OPERATOR) && (
            <Route exact path="/edit" component={EditPage} />
          )}
          {role !== UserRole.CUSTOMER && role !== UserRole.CONTENT && (
            <Styled.OrderContainer>
              <Route exact path="/orders/:orderId" component={OrderPage} />
              <Route exact path="/test-orders/:orderId" component={OrderPage} />
            </Styled.OrderContainer>
          )}
        </Switch>
        {window.location.pathname === '/login' &&
          role !== UserRole.CUSTOMER &&
          role !== UserRole.CONTENT && <Redirect to="/orders" />}
      </Styled.MainPageContentContainer>
      <Footer />
      <NotificationsList />
    </div>
  );
};

export default withAuth(MainPage);
