import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import * as Styled from './EditPage.styles';
import Product from './components/Product';
import Button from '../../components/Button';
import { selectors } from './reducer';
import * as actionService from './actions';
import ProductsSearch from './components/ProductsSearch';
import { selectors as productsSelectors } from '../ProductsPage/reducer';
import * as actions from '../ProductsPage/actions';
import { formatTime, getFromLocalStorage } from '../../global/helpers';
import EditBreadCrumbs from './components/EditBreadCrumbs';
import BlockedProductModal from '../../components/BlockedProductModal/BlockedProductModal';
import DeleteProductModal from '../../components/DeleteProductModal/DeleteProductModal';
import emptyImg from '../../global/media/empty-state.svg';
import { MappingType } from '../ProductsPage/types';

const EditPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const type = history.location.search.split('=').pop();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSuggestionOpen, setSuggestionOpen] = useState<boolean>(false);
  const [isBlockedModalOpen, setBlockedModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const token = getFromLocalStorage('accessToken');
  const selectedProductPharmacy = useSelector(
    productsSelectors.selectedProductData
  );
  const selectedProductService = useSelector(selectors.setSelectedProduct);
  const dataSearch = useSelector(selectors.productsSearchData);
  const dataSearchLoading = useSelector(selectors.productsSearchLoading);
  const productBySkuData = useSelector(selectors.productBySkuData);
  const productBySkuLoading = useSelector(selectors.productBySkuLoading);
  const wareId =
    selectedProductPharmacy !== null
      ? selectedProductPharmacy?.ware_id
      : getFromLocalStorage('selectedProduct')?.ware_id;

  const dataAttAll = useSelector(productsSelectors.attachedAllData);
  const networkForContent = useSelector(productsSelectors.network);
  const storageNetworkForContent = getFromLocalStorage('networkData');

  const { updated_by: updatedBy, updated_at: updatedAt } =
    getFromLocalStorage('selectedProduct');
  const mappingCodeForGlobalSearch =
    getFromLocalStorage('selectedProduct').mapping_code;

  const [srcImg, setSrcImg] = useState(productBySkuData?.img || emptyImg);
  const [srcImgLoading, setSrcImgLoading] = useState(true);

  const dataSearchSkus = dataSearch?.map((el: { sku: string }) => el.sku);
  const isGlobalSearch = getFromLocalStorage('isGlobalSearch');

  const networkCode = networkForContent?.code
    ? networkForContent?.code
    : storageNetworkForContent?.code;

  useEffect(() => {
    if (dataSearchSkus?.length !== 0) {
      dispatch(
        actions.getProductsAttALL.request({
          token,
          code: isGlobalSearch ? mappingCodeForGlobalSearch : networkCode,
          dataSearchSkus,
        })
      );
    }
  }, [
    dispatch,
    networkForContent && networkForContent?.code,
    storageNetworkForContent && storageNetworkForContent?.code,
  ]);

  useEffect(() => {
    if (productBySkuData) {
      setSrcImg(productBySkuData.img);
      setSrcImgLoading(false);
    }
  }, [productBySkuData]);

  useEffect(() => {
    if (
      type === MappingType.ATTACHED ||
      type === MappingType.VERIFICATION ||
      type === MappingType.DYBL ||
      type === MappingType.AUTOMAPPING
    ) {
      dispatch(
        actionService.getProductBySku.request({
          sku: selectedProductPharmacy
            ? selectedProductPharmacy.sku
            : getFromLocalStorage('selectedProduct').sku,
        })
      );
    }
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.length >= 2) {
        dispatch(
          actionService.getProductsSearchForPharmacy.request({
            searchValue,
            postEffect: (dataSearchSkus: string[]) => {
              dispatch(
                actions.getProductsAttALL.request({
                  token,
                  code: isGlobalSearch
                    ? mappingCodeForGlobalSearch
                    : networkCode,
                  dataSearchSkus,
                })
              );
            },
          })
        );
        setSuggestionOpen(true);
      }
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, searchValue, selectedProductPharmacy, type]);

  const handleSuggestionClose = () => {
    setSearchValue('');
    setSuggestionOpen(false);
  };

  const handleOpenBlockModal = () => {
    setBlockedModalOpen(true);
  };

  const handleBlockClick = () => {
    if (type !== MappingType.BLOCKED) {
      dispatch(
        actionService.blockedProduct.request({
          token,
          wareId,
          code: isGlobalSearch ? mappingCodeForGlobalSearch : networkCode,
          postEffect: () => history.push('/products'),
        })
      );
    }
  };

  const handleDeleteClick = () => {
    if (type === MappingType.BLOCKED || type === MappingType.AUTOMAPPING) {
      dispatch(
        actionService.unblockedProduct.request({
          token,
          wareId,
          code: isGlobalSearch ? mappingCodeForGlobalSearch : networkCode,
          postEffect: () => history.push('/products'),
        })
      );
    }
  };

  const handlePostMappings = () => {
    if (
      type === MappingType.ATTACHED ||
      type === MappingType.VERIFICATION ||
      type === MappingType.DYBL ||
      type === MappingType.AUTOMAPPING
    ) {
      dispatch(
        actionService.postDataForMappings.request({
          token,
          sku: selectedProductService
            ? selectedProductService.sku
            : productBySkuData?.sku,
          wareId,
          code: isGlobalSearch ? mappingCodeForGlobalSearch : networkCode,
          postEffect: () => history.push('/products'),
        })
      );
    }
    dispatch(actionService.setSelectedProduct());
  };

  const handleUnBlockClick = () => {
    if (type === MappingType.BLOCKED) setDeleteModalOpen(true);
    if (
      type === MappingType.ATTACHED ||
      type === MappingType.VERIFICATION ||
      type === MappingType.DYBL ||
      type === MappingType.AUTOMAPPING
    ) {
      dispatch(
        actionService.deleteMappings.request({
          token,
          wareId,
          code: isGlobalSearch ? mappingCodeForGlobalSearch : networkCode,
          postEffect: () => history.push('/products'),
        })
      );
    }
    dispatch(actionService.setSelectedProduct());
  };

  const generalProductData =
    selectedProductPharmacy !== null
      ? selectedProductPharmacy
      : getFromLocalStorage('selectedProduct');

  return (
    <>
      <Styled.EditPageContainer>
        <EditBreadCrumbs />
        <Styled.EditPageHeaderContainer>
          <div className="edit-title"> {t('EditPage.TITLE')}</div>
        </Styled.EditPageHeaderContainer>
        <div>
          <Styled.EditPageTable>
            <div className="status-history">{t('EditPage.STATUS_HISTORY')}</div>
            <div
              className={`${
                (type === MappingType.ATTACHED ||
                  type === MappingType.DYBL ||
                  type === MappingType.AUTOMAPPING) &&
                'flexGroup'
              }`}
            >
              <div className="product-table-block">
                <Product data={generalProductData} pharmacy />
                {selectedProductService && (
                  <Product data={selectedProductService} service />
                )}
                {productBySkuLoading && !searchValue.length ? (
                  <div className="product-loading">
                    <CircularProgress size={25} />
                  </div>
                ) : (
                  productBySkuData &&
                  !selectedProductService &&
                  (type === MappingType.ATTACHED ||
                    type === MappingType.VERIFICATION ||
                    type === MappingType.DYBL ||
                    type === MappingType.AUTOMAPPING) && (
                    <Product data={productBySkuData} service />
                  )
                )}
              </div>
              {(type === MappingType.ATTACHED ||
                type === MappingType.DYBL ||
                type === MappingType.AUTOMAPPING) &&
                productBySkuData &&
                !srcImgLoading && (
                  <Styled.InfoSelectedProduct>
                    <div className="product-block">
                      <div className="product-img">
                        <img
                          src={srcImg}
                          onError={() => setSrcImg(emptyImg)}
                          alt="product img"
                        />
                      </div>
                      <div className="product-name">
                        {productBySkuData.name}
                      </div>
                      <div className="product-packing">
                        {productBySkuData.packing}
                      </div>
                      <div className="product-manufacturer">
                        {productBySkuData.manufacturer}
                      </div>
                    </div>
                  </Styled.InfoSelectedProduct>
                )}
            </div>
          </Styled.EditPageTable>

          {(type === MappingType.ATTACHED ||
            type === MappingType.VERIFICATION) &&
            (updatedAt || updatedBy) && (
              <Styled.AttachedByBlock>
                <div className="verified-title">{t('EditPage.VERIFIED')}</div>
                <div>{`+${updatedBy || ''} / ${
                  formatTime(updatedAt) || ''
                }`}</div>
              </Styled.AttachedByBlock>
            )}

          {(type === MappingType.UNATTACHED ||
            type === MappingType.ATTACHED ||
            type === MappingType.VERIFICATION ||
            type === MappingType.DYBL ||
            type === MappingType.AUTOMAPPING) && (
            <>
              <div className="product-modal__condition">
                {t('EditPage.CONDITION_FOR_CHANGING')}
              </div>
              <ProductsSearch
                value={searchValue}
                results={dataSearch}
                attachedProduct={dataAttAll?.mappings}
                loading={dataSearchLoading}
                selectedProductPharmacy={selectedProductPharmacy}
                placeholder={t('EditPage.SEARCH_PLACEHOLDER')}
                setValue={setSearchValue}
                isSuggestionOpen={isSuggestionOpen}
                onSuggestionClose={handleSuggestionClose}
                type={type}
              />
            </>
          )}

          {type === MappingType.BLOCKED && (
            <div className="product-modal__button">
              <Button
                onClick={handleUnBlockClick}
                text={t('EditPage.BTN_TEXT_DELETE')}
              />
            </div>
          )}
          {(type === MappingType.ATTACHED ||
            type === MappingType.VERIFICATION ||
            type === MappingType.DYBL ||
            type === MappingType.AUTOMAPPING) && (
            <Styled.ButtonContainer>
              <div className="product-modal__button">
                <Button
                  onClick={handlePostMappings}
                  text={t('EditPage.BTN_TEXT_CONFIRM')}
                />
              </div>
              <div className="product-modal__block-button">
                <Button
                  onClick={handleOpenBlockModal}
                  text={t('EditPage.BTN_TEXT_BLOCK')}
                  variant="outlined"
                />
              </div>
              <div className="product-modal__delete-button">
                <Button
                  onClick={() => setDeleteModalOpen(true)}
                  text={t('EditPage.BTN_TEXT_DELETE_MAP')}
                  variant="outlined"
                />
              </div>
            </Styled.ButtonContainer>
          )}
          {type === MappingType.UNATTACHED && (
            <div className="product-modal__block-button">
              <Button
                onClick={handleOpenBlockModal}
                text={t('EditPage.BTN_TEXT_BLOCK')}
                variant="outlined"
              />
            </div>
          )}
        </div>
      </Styled.EditPageContainer>
      {isDeleteModalOpen && (
        <DeleteProductModal
          onClose={() => setDeleteModalOpen(false)}
          onDelete={handleDeleteClick}
          onDeleteMap={handleUnBlockClick}
        />
      )}
      {isBlockedModalOpen && (
        <BlockedProductModal
          onClose={() => setBlockedModalOpen(false)}
          onBlock={handleBlockClick}
        />
      )}
    </>
  );
};

export default EditPage;
