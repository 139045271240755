import axios from 'axios';
import { getFromLocalStorage } from '../../global/helpers';
import { getTagsRowForService } from './helpers';

export class ProductsService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static role = getFromLocalStorage('role');

  static getProductsUnAttached = async (
    token: string,
    num: string,
    tag: string[],
    code: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;

    const url =
      tag?.length > 0
        ? `${ProductsService.API_BASE_URL}mappings/tag?${getTagsRowForService(
            tag
          )}&type=unmapped&mapping_code=${code}&offset=${offset.toString()}&limit=100`
        : `${
            ProductsService.API_BASE_URL
          }mappings?type=unmapped&mapping_code=${code}&offset=${offset.toString()}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsAttached = async (
    token: string,
    num: string,
    tag: string[],
    code: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;
    const url =
      tag?.length > 0
        ? `${ProductsService.API_BASE_URL}mappings/tag?${getTagsRowForService(
            tag
          )}&type=verified&mapping_code=${code}&offset=${offset.toString()}&limit=100`
        : `${
            ProductsService.API_BASE_URL
          }mappings?type=verified&mapping_code=${code}&offset=${offset.toString()}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsDybl = async (
    token: string,
    num: string,
    tag: string[],
    code: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;
    const url =
      tag?.length > 0
        ? `${
            ProductsService.API_BASE_URL
          }mappings/tag?tag=1&${getTagsRowForService(
            tag
          )}&mapping_code=${code}&offset=${offset.toString()}&limit=100`
        : `${
            ProductsService.API_BASE_URL
          }mappings/tag?tag=1&mapping_code=${code}&offset=${offset.toString()}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsAttachedAll = async (
    token: string,
    code: string,
    skus: string[]
  ) => {
    const response = await axios({
      method: 'get',
      url: `${
        ProductsService.API_BASE_URL
      }mappings?type=verified&mapping_code=${code}&skus=${skus.toString()}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsBlocked = async (
    token: string,
    num: string,
    tag: string[],
    code: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;
    const url =
      tag?.length > 0
        ? `${ProductsService.API_BASE_URL}mappings/tag?${getTagsRowForService(
            tag
          )}&type=blocked&mapping_code=${code}&offset=${offset.toString()}&limit=100`
        : `${
            ProductsService.API_BASE_URL
          }mappings?type=blocked&mapping_code=${code}&offset=${offset.toString()}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsBlockedAll = async (
    token: string,
    code: string,
    skus: string[]
  ) => {
    const response = await axios({
      method: 'get',
      url: `${
        ProductsService.API_BASE_URL
      }mappings?type=blocked&mapping_code=${code}&skus=${skus.toString()}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsOnVerification = async (
    token: string,
    num: string,
    tag: string[],
    code: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;
    const url =
      tag?.length > 0
        ? `${ProductsService.API_BASE_URL}mappings/tag?${getTagsRowForService(
            tag
          )}&type=on_verification&mapping_code=${code}&offset=${offset.toString()}&limit=100`
        : `${
            ProductsService.API_BASE_URL
          }mappings?type=on_verification&mapping_code=${code}&offset=${offset.toString()}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsOnAutomapping = async (
    token: string,
    num: string,
    tag: string[],
    code: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;
    const url =
      tag?.length > 0
        ? `${ProductsService.API_BASE_URL}mappings/tag?${getTagsRowForService(
            tag
          )}&type=automapping&mapping_code=${code}&offset=${offset.toString()}&limit=100`
        : `${
            ProductsService.API_BASE_URL
          }mappings?type=automapping&mapping_code=${code}&offset=${offset.toString()}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsOnAutomappingAll = async (
    token: string,
    code: string,
    skus: string[]
  ) => {
    const response = await axios({
      method: 'get',
      url: `${
        ProductsService.API_BASE_URL
      }mappings?type=automapping&mapping_code=${code}&skus=${skus.toString()}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsOnVerificationAll = async (
    token: string,
    code: string,
    skus: string[]
  ) => {
    const response = await axios({
      method: 'get',
      url: `${
        ProductsService.API_BASE_URL
      }mappings?type=on_verification&mapping_code=${code}&skus=${skus.toString()}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsSearchOnVerification = async (
    token: string,
    code: string,
    searchValue?: string,
    tag?: string[],
    num?: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;

    const url = tag
      ? `${ProductsService.API_BASE_URL}mappings/search?${getTagsRowForService(
          tag
        )}&type=on_verification${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset}&limit=100`
      : `${ProductsService.API_BASE_URL}mappings/search?type=on_verification${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsSearchAutomapping = async (
    token: string,
    code: string,
    searchValue?: string,
    tag?: string[],
    num?: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;

    const url = tag
      ? `${ProductsService.API_BASE_URL}mappings/search?${getTagsRowForService(
          tag
        )}&type=automapping${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset}&limit=100`
      : `${ProductsService.API_BASE_URL}mappings/search?type=automapping${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsSearchUnAttached = async (
    token: string,
    code: string,
    searchValue?: string,
    tag?: string[],
    num?: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;
    const url = tag
      ? `${ProductsService.API_BASE_URL}mappings/search?${getTagsRowForService(
          tag
        )}&type=unmapped${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset.toString()}&limit=100 `
      : `${ProductsService.API_BASE_URL}mappings/search?type=unmapped${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsSearchAttached = async (
    token: string,
    code: string,
    searchValue?: string,
    tag?: string[],
    num?: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;

    const url = tag
      ? `${ProductsService.API_BASE_URL}mappings/search?${getTagsRowForService(
          tag
        )}&type=verified${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset.toString()}&limit=100`
      : `${ProductsService.API_BASE_URL}mappings/search?type=verified${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset.toString()}&limit=100`;

    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  static getProductsSearchBlocked = async (
    token: string,
    code: string,
    searchValue?: string,
    tag?: string[],
    num?: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;

    const url = tag
      ? `${ProductsService.API_BASE_URL}mappings/search?${getTagsRowForService(
          tag
        )}&type=blocked${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset.toString()}&limit=100`
      : `${ProductsService.API_BASE_URL}mappings/search?type=blocked${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset.toString()}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getProductsSearchDybl = async (
    token: string,
    code: string,
    searchValue?: string,
    tag?: string[],
    num?: string
  ) => {
    const offset = num === '0' ? num : Number(num) - 100;

    const url = tag
      ? `${
          ProductsService.API_BASE_URL
        }mappings/search?tag=1&${getTagsRowForService(tag)}${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset.toString()}&limit=100`
      : `${ProductsService.API_BASE_URL}mappings/search?tag=1${
          code && `&mapping_code=${code}`
        }&q=${searchValue}&offset=${offset.toString()}&limit=100`;
    const response = await axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getAllNetworks = async () => {
    const response = await axios({
      method: 'get',
      url: `${ProductsService.API_BASE_URL}mappings/codes`,
    });
    return response.data;
  };

  static getAllTags = async () => {
    const response = await axios({
      method: 'get',
      url: `${ProductsService.API_BASE_URL}tags`,
    });
    return response.data;
  };

  static setTagsToProduct = async (
    tags: number[],
    wareId: string,
    mappingCode: string
  ) => {
    const data = { ware_id: wareId, mapping_code: mappingCode, tag: tags };
    const response = await axios({
      method: 'put',
      url: `${ProductsService.API_BASE_URL}mappings/tag`,
      data,
    });
    return response.data;
  };

  static deleteProductTag = async (
    tag: number,
    wareId: string,
    mappingCode: string
  ) => {
    const data = { ware_id: wareId, mapping_code: mappingCode, tag };
    const response = await axios({
      method: 'delete',
      url: `${ProductsService.API_BASE_URL}mappings/tag`,
      data,
    });
    return response.data;
  };
}
