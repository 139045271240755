export interface ProductResponse {
  agent_commission: number | string;
  blocked: boolean;
  country: string;
  manufacturer: string;
  name: string;
  network_code: string;
  sku: string;
  skuName: string;
  skuManufacturer: string;
  urlKey: string;
  ware_id: string;
  warehouse_discount: number | string;
  with_recipe: boolean;
  max_price: number;
  min_price: number;
  avg_price: number;
  quantity: number;
  status: string;
  updated_by: string;
  updated_at: string;
  mapping_code?: string;

  tags: {
    meta: string;
    tag: number;
    name: string;
  }[];
}

export interface ProductsResponse {
  status: string;
  result: {
    mappings: ProductsResponse[];
    total_count: number;
    total_quantity: number;
  };
}

export interface ProductsSearchResponse {
  status: string;
  result: {
    mappings: ProductsResponse[];
    total_count: number;
  };
}

export interface ProductsResult {
  mappings: ProductsResponse[];
  total_count: number;
  total_quantity: number;
}

export interface CountPropsNumb {
  countUnAttached: number;
  countAttached: number;
  countBlocked: number;
  countOnVerification: number;
  countDybl: number;
  countAutomapping: number;
}

export interface CountPropsStr {
  countUnAttached: string;
  countAttached: string;
  countBlocked: string;
  countOnVerification: string;
  countDybl: string;
  countAutomapping: string;
}

export interface ProductTableData {
  dataUnAttached: ProductsResponse | null | undefined;
  dataAttached: ProductsResponse | null | undefined;
  dataBlocked: ProductsResponse | null | undefined;
  dataOnVerification: ProductsResponse | null | undefined;
  dataAutomapping: ProductResponse | null | undefined;
}

export interface TagsFieldResponse {
  required_meta: boolean;
  color: string;
  name: string;
  id: number;
  allowed: boolean;
}

export interface TagsMappedInfo {
  tagName: string;
  tagColor: string;
  tagRequiredMate: boolean;
  tagId: string;
  allowed: boolean;
}

export interface ProductsPageState {
  productsUnAttached: {
    data: ProductsResult | null;
    error: null | string;
    loading: boolean;
  };

  productsAttached: {
    data: ProductsResult | null;
    error: null | string;
    loading: boolean;
  };

  productsAttachedAll: {
    data: ProductsResult | null;
    error: null | string;
    loading: boolean;
  };

  productsBlocked: {
    data: ProductsResult | null;
    error: null | string;
    loading: boolean;
  };

  productsVerification: {
    data: ProductsResult | null;
    error: null | string;
    loading: boolean;
  };

  productsAutomapping: {
    data: ProductsResult | null;
    error: null | string;
    loading: boolean;
  };

  productsDybl: {
    data: ProductsResult | null;
    error: null | string;
    loading: boolean;
  };

  productsSearchUnAttached: {
    data: null | ProductResponse[];
    error: null | string;
    loading: boolean;
  };

  productsSearchAttached: {
    data: null | ProductResponse[];
    error: null | string;
    loading: boolean;
  };

  productsSearchBlocked: {
    data: null | ProductResponse[];
    error: null | string;
    loading: boolean;
  };

  productsSearchOnVerification: {
    data: null | ProductResponse[];
    error: null | string;
    loading: boolean;
  };

  productsSearchDybl: {
    data: null | ProductResponse[];
    error: null | string;
    loading: boolean;
  };

  productsSearchAutomapping: {
    data: null | ProductResponse[];
    error: null | string;
    loading: boolean;
  };

  editType: {
    selectedProduct: ProductResponse | null;
  };

  openNetworks: boolean;
  network: null | { name: string; code: string };
  tagIds: null | string[];

  networksData: {
    data: any;
    error: null | string;
    loading: boolean;
  };

  tagsData: {
    data: TagsMappedInfo[] | null;
    error: null | string;
    loading: boolean;
  };
}

export enum MappingType {
  BLOCKED = 'blocked',
  VERIFICATION = 'verification',
  ATTACHED = 'attached',
  UNATTACHED = 'unattached',
  DYBL = 'dybl',
  AUTOMAPPING = 'automapping',
}

export enum ProductStatus {
  VERIFIED = 'verified',
  ON_VERIFICATION = 'on_verification',
  BLOCKED = 'blocked',
  UNMAPPED = 'unmapped',
  AUTOMAPPING = 'automapping',
}
