import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ClickAwayListener } from '@mui/material';
import * as Styled from './SelectTags.styles';
import * as actions from '../../../../actions';
import SelectTag from './SelectTag';
import Button from '../../../../../../components/Button';
import { TagsMappedInfo } from '../../../../types';
import AddDeleteTagModal from '../../../AddDeleteTagModal';
import { getFromLocalStorage } from '../../../../../../global/helpers';
import { UserRole } from '../../../../../OrdersPage/types';

interface SelectTagsProps {
  onClick: (ids: string[]) => void;
  onClose: () => void;
  data: TagsMappedInfo[];
  isAdd?: boolean;
  wareId?: string;
  mappingCode?: string;
  productName?: string;
  setData: any;
}

const SelectTags = ({
  onClick,
  onClose,
  data,
  isAdd,
  wareId,
  mappingCode,
  productName,
  setData,
}: SelectTagsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isConfirmModalOpen, setModalOpen] = useState<boolean>(false);
  const role = getFromLocalStorage('role');

  const handleAddToTags = (id: string) => {
    if (id === selectedTags.find((el) => el === id)) {
      setSelectedTags([...selectedTags.filter((el) => el !== id)]);
    } else setSelectedTags([...selectedTags.filter((el) => el !== ''), id]);
  };

  const handleSaveTags = () => {
    if (wareId && mappingCode) {
      dispatch(
        actions.setTagsToProduct.request({
          tags: selectedTags,
          wareId,
          mappingCode,
          postEffect: () => {
            setData();
          },
        })
      );
    }
  };

  const handleClickModalBtn = () => {
    if (isAdd) {
      setModalOpen(true);
    } else {
      onClick(selectedTags);
    }
  };

  const handleClose = () => setModalOpen(false);

  const operatorTags = data.filter(
    (tag: TagsMappedInfo) => tag.tagColor === '#F2DA00'
  );

  const tagsToDisplay =
    role === UserRole.OPERATOR || role === UserRole.HEAD_OPERATOR
      ? operatorTags
      : data;

  const renderTags = (tags: TagsMappedInfo[]) => {
    return tags.map((item: TagsMappedInfo) => (
      <Styled.Tag key={item.tagId} color={`${item.tagColor}`}>
        <SelectTag
          name={item.tagName}
          id={item.tagId}
          onClick={handleAddToTags}
        />
      </Styled.Tag>
    ));
  };

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <Styled.TagsContainer isAdd={isAdd}>
          <>
            <div>{renderTags(tagsToDisplay)}</div>

            <div className="sticky-btn">
              <Button
                onClick={handleClickModalBtn}
                text={t('CodeConfirmation.CONFIRM')}
                variant="outlined"
              />
            </div>
          </>
        </Styled.TagsContainer>
      </ClickAwayListener>
      {isConfirmModalOpen && (
        <AddDeleteTagModal
          onClose={handleClose}
          tagsId={selectedTags}
          productName={productName ?? ''}
          onConfirm={handleSaveTags}
          variant="add"
        />
      )}
    </>
  );
};

export default SelectTags;
