import React, { useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Styled from './SourcesPage.styles';
import * as actions from './actions';
import * as actionSource from '../SourcePage/actions';
import * as actionNetwork from '../NetworkPage/actions';
import Button from '../../components/Button';
import { selectors as sourceSelectors } from '../SourcePage/reducer';
import filterIcon from '../../global/media/filter.svg';
import { selectors } from './reducer';
import deleteIcon from '../../global/media/delete.svg';
import Source from './components/Source/Source';
import Loader from '../../components/Loader';
import CustomCheckbox from '../../components/CustomCheckbox';
import { MappedTag, SourceData, TagsFieldResponse } from './types';
import {
  FiltersPharmacies,
  FiltersPharmaciesCities,
} from '../NetworkPage/types';
import SelectTags from './components/SelectTags';

type Sources = 'name' | 'code' | 'networkCode';

const SourcesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string>('');
  const [param, setParam] = useState<Sources>('name');
  const sources = useSelector(selectors.sourcesData);
  const [filterSourceCities, setFilterSourceCities] = useState(sources);
  const [filterSource, setFilterSource] = useState(sources);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isOpenTagsOpen, setTagsOpen] = useState<boolean>(false);
  const [tagName, setTagName] = useState<
    { tag: string; color: string; id: string }[]
  >([]);
  const [name, setName] = useState<string[] | undefined>([]);
  const [filters, setFilters] = useState<FiltersPharmacies>({
    all: true,
    visible: false,
    invisible: false,
    synchronization: false,
    on: false,
    off: false,
  });
  const sourceTagsData = useSelector(sourceSelectors.sourceTagsData);

  useEffect(() => {
    dispatch(actionSource.getSourceTags.request({ tagsType: 'source' }));
  }, []);

  const [filtersCities, setFiltersCities] = useState<FiltersPharmaciesCities>({
    almaty: true,
    astana: true,
    shymkent: true,
    karaganda: true,
  });

  useEffect(() => {
    dispatch(actions.getSources.request());
    dispatch(actionSource.clearSource());
    dispatch(actionNetwork.clearNetwork());
    dispatch(actionNetwork.clearSources());
    dispatch(actionSource.getSourceTags.request({ tagsType: 'source' }));
  }, [dispatch]);

  const hour = 3600000;

  const getFiltersDataCities = (
    arr: SourceData[],
    { ...params }: FiltersPharmaciesCities
  ) => {
    if (
      filtersCities.almaty &&
      filtersCities.astana &&
      filtersCities.shymkent &&
      filtersCities.karaganda
    ) {
      return arr;
    }
    return Object.keys(params).reduce((acc) => {
      const filterData = acc.filter((pharmacy) => {
        switch (true) {
          case params.almaty && params.shymkent:
            return pharmacy.city !== 'Астана';
          case params.almaty && params.astana:
            return pharmacy.city !== 'Шымкент';
          case params.shymkent && params.astana:
            return pharmacy.city !== 'Алматы';
          case params.almaty:
            return pharmacy.city === 'Алматы';
          case params.astana:
            return pharmacy.city === 'Астана';
          case params.shymkent:
            return pharmacy.city === 'Шымкент';
          case params.karaganda:
            return pharmacy.city === 'Караганда';
          default:
            return true;
        }
      });

      return filterData;
    }, arr);
  };

  const getFiltersDataTags = filterSource?.filter((resource: SourceData) =>
    resource?.tags?.some((value: any) =>
      selectedTags.includes(value.tag_id.toString())
    )
  );

  const newTags = (data: TagsFieldResponse[] | null): MappedTag[] => {
    return (
      data?.map((el) => ({
        tagName: el.name,
        tagColor: el.color || '',
        tagRequiredMeta: el.required_meta || false,
        tagId: el.id.toString(),
        allowed: el.allowed || false,
        isChecked: false,
      })) || []
    );
  };

  const mappedData = newTags(sourceTagsData);

  const handleTagCLick = (ids: string[]) => {
    const mappedTags = () => {
      return ids.map((e) => {
        const tag = mappedData?.find((el: { tagId: string }) => el.tagId === e);
        return {
          tag: tag?.tagName,
          color: tag?.tagColor,
          id: e,
        };
      });
    };
    if (ids) {
      setTagName(mappedTags as any);
    }
    setTagsOpen(false);
  };

  const updateCheckedTags = (
    mappedData: MappedTag[],
    selectedTags: string[]
  ): MappedTag[] => {
    return (
      mappedData &&
      mappedData.map((tag) => {
        if (selectedTags.includes(tag.tagId.toString())) {
          return { ...tag, isChecked: true };
        }
        return tag;
      })
    );
  };

  const updatedMappedData = updateCheckedTags(mappedData, selectedTags);

  const getSelectedTagNames = () => {
    const filteredTagNames =
      mappedData &&
      mappedData
        .filter((tag) => selectedTags.includes(tag.tagId))
        .map((tag) => tag.tagName);
    setName(filteredTagNames);
  };

  const handleButtonClick = () => {
    selectedTags.length > 0 &&
      getFiltersDataTags &&
      setFilterSource(getFiltersDataTags);
    getSelectedTagNames();
  };

  const getFiltersData = (
    arr: SourceData[],
    { all, ...params }: FiltersPharmacies
  ) => {
    if (all) {
      return arr;
    }
    return Object.keys(params).reduce((acc, filter) => {
      const filterData = acc.filter((pharmacy) => {
        switch (filter) {
          case 'visible':
            return pharmacy.visible === params.visible;
          case 'invisible':
            return !pharmacy.visible === params.invisible;
          case 'on':
            return pharmacy.activated === params.on;
          case 'off':
            return !pharmacy.activated === params.off;
          case 'synchronization':
            if (params.synchronization) {
              return (
                pharmacy.lastSyncOrigin &&
                Date.now() - Date.parse(pharmacy.lastSyncOrigin) > hour &&
                Date.parse(pharmacy.lastSyncOrigin) !== -62135596800000
              );
            }
            return true;
          default:
            return true;
        }
      });

      return filterData;
    }, arr);
  };

  const handleResetTags = () => {
    setSelectedTags([]);
    setName([]);
    setFilterSource(sources);
  };

  useEffect(() => {
    if (filterSourceCities && filterSourceCities?.length > 0) {
      setFilterSource(getFiltersData(filterSourceCities, filters));
    }
  }, [filterSourceCities, filters]);

  useEffect(() => {
    if (sources && sources?.length > 0) {
      setFilterSourceCities(getFiltersDataCities(sources, filtersCities));
    }
  }, [sources, filtersCities]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParam(event.target.value as Sources);
  };
  const onSearch = () => {
    setFilterSource(
      // @ts-ignore
      sources?.filter((pharmacy) =>
        pharmacy[param as Sources]
          .toLocaleLowerCase()
          ?.includes(searchValue.toLocaleLowerCase())
      )
    );
  };

  useEffect(() => {
    onSearch();
  }, [param]);

  const handleToSource = (code: string) => {
    history.push(`/sources/${code}`);
  };

  const handleToNetwork = (code: string) => {
    history.push(`/networks/${code}`);
  };

  const handleToCreate = (source: boolean) => {
    if (source) {
      history.push(`/create#source`);
    } else {
      history.push(`/create#network`);
    }
  };

  const handleToPharmacyTags = () => {
    history.push('/pharmacy-tags');
  };

  return (
    <>
      {!sources && <Loader />}
      <Styled.AdminPageContainer>
        <Styled.ButtonsBlock>
          <div className="add-network-btn">
            <Button
              text={t('SourcesPage.ADD_NETWORK')}
              onClick={() => handleToCreate(false)}
            />
          </div>
          <Button
            text={t('SourcesPage.ADD_SOURCE')}
            onClick={() => handleToCreate(true)}
            variant="outlined"
          />
          <div style={{ marginLeft: '15px' }}>
            <Button
              text={t('SourcesPage.ADD_TAGS')}
              onClick={() => handleToPharmacyTags()}
              variant="outlined"
            />
          </div>
        </Styled.ButtonsBlock>
        <div>
          <Styled.SearchContainer>
            <Styled.ContainerInput>
              <input
                autoComplete="off"
                value={searchValue}
                type="text"
                className="search-input"
                placeholder="Поиск аптек"
                onChange={handleSearch}
                onFocus={() => {
                  setFilters({
                    all: true,
                    visible: false,
                    invisible: false,
                    synchronization: false,
                    on: false,
                    off: false,
                  });
                  setFiltersCities({
                    almaty: true,
                    astana: true,
                    shymkent: true,
                    karaganda: true,
                  });
                }}
              />
              {searchValue && (
                <div className="clear-input" onClick={() => setSearchValue('')}>
                  {t('SourcesPage.CLEAR')}
                </div>
              )}
            </Styled.ContainerInput>
            <Styled.ButtonSearch onClick={() => onSearch()}>
              {t('SourcesPage.SEARCH')}
            </Styled.ButtonSearch>
          </Styled.SearchContainer>
          <Styled.ContainerRadioBox>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue="name"
              row
              name="radio-buttons-group"
              onChange={handleChange}
            >
              <FormControlLabel value="name" control={<Radio />} label="name" />
              <FormControlLabel
                value="networkCode"
                control={<Radio />}
                label="network code"
              />
              <FormControlLabel value="code" control={<Radio />} label="code" />
            </RadioGroup>
          </Styled.ContainerRadioBox>
          <div className="filter-container-cities">
            <p>{t('SourcesPage.FILTERS')}</p>
            <CustomCheckbox
              title="Алматы"
              id="almaty"
              onChange={() => {
                setFilters({
                  ...filters,
                  all: true,
                  visible: false,
                  invisible: false,
                  synchronization: false,
                  on: false,
                  off: false,
                });
                setFiltersCities({
                  ...filtersCities,
                  almaty: !filtersCities.almaty,
                });
              }}
              checked={filtersCities.almaty}
            />
            <CustomCheckbox
              title="Аcтана"
              id="astana"
              onChange={() => {
                setFilters({
                  ...filters,
                  all: true,
                  visible: false,
                  invisible: false,
                  synchronization: false,
                  on: false,
                  off: false,
                });
                setFiltersCities({
                  ...filtersCities,
                  astana: !filtersCities.astana,
                });
              }}
              checked={filtersCities.astana}
            />
            <CustomCheckbox
              title="Шымкент"
              id="shymkent"
              onChange={() => {
                setFilters({
                  ...filters,
                  all: true,
                  visible: false,
                  invisible: false,
                  synchronization: false,
                  on: false,
                  off: false,
                });
                setFiltersCities({
                  ...filtersCities,
                  shymkent: !filtersCities.shymkent,
                });
              }}
              checked={filtersCities.shymkent}
            />
            <CustomCheckbox
              title="Караганда"
              id="karaganda"
              onChange={() => {
                setFilters({
                  ...filters,
                  all: true,
                  visible: false,
                  invisible: false,
                  synchronization: false,
                  on: false,
                  off: false,
                });
                setFiltersCities({
                  ...filtersCities,
                  karaganda: !filtersCities.karaganda,
                });
              }}
              checked={filtersCities.karaganda}
            />
          </div>
          <div className="flex-container">
            <CustomCheckbox
              title="Все"
              id="all"
              onChange={() => {
                setFilters({
                  ...filters,
                  all: !filters.all,
                  visible: false,
                  invisible: false,
                  synchronization: false,
                  on: false,
                  off: false,
                });
              }}
              checked={filters.all}
            />
            <CustomCheckbox
              title="Видима"
              id="visible"
              onChange={() => {
                setFilters({
                  ...filters,
                  visible: !filters.visible,
                  invisible: false,
                  all: false,
                });
              }}
              checked={filters.visible}
            />
            <CustomCheckbox
              title="Невидима"
              id="invisible"
              onChange={() => {
                setFilters({
                  ...filters,
                  visible: false,
                  invisible: !filters.invisible,
                  all: false,
                });
              }}
              checked={filters.invisible}
            />
            <CustomCheckbox
              title="Синх более часа"
              id="synchronization"
              onChange={() => {
                setFilters({
                  ...filters,
                  synchronization: !filters.synchronization,
                  all: false,
                });
              }}
              checked={filters.synchronization}
            />

            <CustomCheckbox
              title="Включена"
              id="on"
              onChange={() => {
                setFilters({
                  ...filters,
                  on: !filters.on,
                  off: false,
                  all: false,
                });
              }}
              checked={filters.on}
            />
            <CustomCheckbox
              title="Выключена"
              id="off"
              onChange={() => {
                setFilters({
                  ...filters,
                  on: false,
                  off: !filters.off,
                  all: false,
                });
              }}
              checked={filters.off}
            />
          </div>
          <Styled.FilterTags>
            <div className="tags-block">
              <div
                className="set-tag"
                onClick={() => setTagsOpen(!isOpenTagsOpen)}
              >
                <div className="text">Теги</div>
                <img src={filterIcon} alt="filter icon" />
              </div>
              {name && name.length > 0 && (
                <div className="filter-info">
                  <span>
                    <span className="filter-by">Фильтр по: </span>
                    <span>{name?.map((el) => el).join(', ')}</span>

                    <span onClick={handleResetTags}>
                      <img src={deleteIcon} alt="delete icon" />
                    </span>
                  </span>
                </div>
              )}
            </div>
            {isOpenTagsOpen && (
              <SelectTags
                onClick={handleTagCLick}
                onClose={() => setTagsOpen(false)}
                data={updatedMappedData}
                setData={() => {}}
                isSource
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                handleButtonClick={handleButtonClick}
              />
            )}
          </Styled.FilterTags>
        </div>

        {filterSource &&
          filterSource?.map((source) => (
            <Styled.Source key={source.code}>
              <Styled.NameSourceBLock>
                <div
                  className="name"
                  onClick={() => handleToSource(source.code)}
                >
                  {source.name}
                </div>

                <div
                  className="network-name-block"
                  onClick={() => handleToNetwork(source.networkCode)}
                >
                  <div className="network">{t('SourcesPage.NETWORK')}</div>
                  <div className="name-network">{source.networkName}</div>
                </div>
              </Styled.NameSourceBLock>
              <Source data={source} />
            </Styled.Source>
          ))}
      </Styled.AdminPageContainer>
    </>
  );
};

export default SourcesPage;
