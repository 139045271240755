import styled from 'styled-components';
import { baseTheme } from '../../../../../../global/styles/theme';

export const SourceRow = styled('tr')`
  .table-cell {
    padding: 24px 10px 16px 0;
    font-size: 16px;
    font-weight: 400;
    color: ${baseTheme.colors.primary};
    cursor: pointer;

    div {
      .disableUpdatedBy {
        margin-top: 10px;
        color: ${baseTheme.colors.primary};
        font-weight: 400;
        span {
          font-weight: 500;
        }
      }
    }
  }

  .input-comments-error {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border: solid 1px #eb5757;
  }

  .input-comments {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border: solid 1px #000;
  }
  .comment-error {
    margin: 0;
    color: #eb5757;
  }

  .comment {
    padding: 7px;
    border: solid 1px;
    border-radius: 3px;
  }

  .container-error {
    margin-top: 10px;
    font-weight: 500;
  }

  .error {
    display: inline;
    font-size: 18px;
    color: #eb5757;
  }

  .save-btn {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    color: ${baseTheme.colors.white};
    padding: 10px 24px;
    background-color: ${baseTheme.colors.mainLightBlue};
    border-radius: 8px;
    width: 143px;
  }

  .disable {
    background-color: ${baseTheme.colors.disabled} !important;
    pointer-events: none;
  }

  .toggle-block {
    display: flex;

    .disableUpdatedAt {
      color: ${baseTheme.colors.secondary};
      padding-left: 8px;
      display: flex;
      align-items: center;
    }
  }
  .toggle-block-visible {
    align-items: center;
    margin-top: 10px;
    gap: 10px;
    display: flex;
  }
`;
