import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import './index.scss';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: 'https://5833b86054414a5e9817f39bc1f1c105@o4504695079501824.ingest.sentry.io/4505510747963397',
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [
          window.location.origin,
          'https://prod-backoffice.daribar.com/api/v1/reports/orders/payments',
          'https://prod-backoffice.daribar.com/api/v1/orders',
          'https://prod-backoffice.daribar.com/api/v1/orders/not_muted',
          'https://prod-backoffice.daribar.com/api/v1/users',
        ],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 5,
  });
}

store()
  .then((s) => {
    ReactDOM.render(
      <Provider store={s}>
        <App />
      </Provider>,
      document.getElementById('root')
    );
  })
  .catch((err) => {
    console.log('err');
    // eslint-disable-next-line
    console.error(err.message);
  });
