import styled from 'styled-components';

export const MainPageContentContainer = styled('div')`
  min-height: calc(100vh - 200px);
  padding: 0 32px;
  position: relative;
`;

export const OrderContainer = styled('div')`
  margin: 0 -32px;

  @media screen and (min-width: 1800px) {
    display: flex;
    justify-content: center;
    margin: 0;
  }
`;
