import axios from 'axios';
import { N8nParams } from './saga';

export class OrderService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static API_UPGRADE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v2/`;

  static API_SEARCH_BASE_ULR = `${process.env.REACT_APP_SEARCH_URL}/rest/default/V1/products?`;

  static getProducts = async (searchValue: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrderService.API_SEARCH_BASE_ULR}searchCriteria[page_size]=50&searchCriteria[filter_groups][0][filters][0][field]=name&searchCriteria[filter_groups][0][filters][0][value]=%25${searchValue}%25&searchCriteria[filter_groups][0][filters][0][condition_type]=like`,
    });
    return response.data;
  };

  static getAllStatuses = async (token: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrderService.API_UPGRADE_URL}orders/statuses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static getAllStatusesPharmacy = async (token: string) => {
    const response = await axios({
      method: 'get',
      url: `${OrderService.API_UPGRADE_URL}orders/pharmacy_statuses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  static putOrder = async (orderInfo: any, id: any) => {
    const response = await axios({
      method: 'put',
      url: `${OrderService.API_BASE_URL}orders/${id}/edit`,
      data: orderInfo,
    });
    return response.data;
  };

  static postRecipe = async (token: string, data: any) => {
    const formData = new FormData();
    formData.append('file', data);

    if (formData) {
      const response = await axios({
        method: 'post',

        url: `${OrderService.API_BASE_URL}users/receipts`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });

      return response.data;
    }
  };

  static getProductsBySkus = async (skus: string[]) => {
    const response = await axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_SEARCH_URL
      }/rest/default/V1/products?searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=${skus.join()}&searchCriteria[filter_groups][0][filters][0][condition_type]=in`,
    });
    return response.data;
  };

  static getApproximatePrice = async (skuArray: string[]) => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_BASE_URL}prices`,
      data: JSON.stringify(skuArray),
      headers: { 'Content-Type': 'text/plain' },
    });
    return response.data;
  };

  static n8nPrice = async (data: N8nParams) => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_UPGRADE_URL}orders/delivery/fact_price`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
    return response.data;
  };

  static applyPromoCode = async (id: string, data: string) => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_UPGRADE_URL}admin/orders/${id}/apply-promocode`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: {
        promocode: data,
      },
    });
    return response.data;
  };

  static sendEdit = async (
    id: string,
    data: [
      {
        name: 'string';
        need_receipt: true;
        other_price: true;
        out_of_stock: true;
        packing_diff: true;
        sku: 'string';
        term: true;
      }
    ]
  ) => {
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_UPGRADE_URL}orders/${id}/send_edit`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: {
        items: data,
      },
    });
    return response.data;
  };

  static getPriceWithQuantityBySource = async (
    source: string,
    orderId: string,
    array: string[],
    wareId?: boolean
  ) => {
    const data = wareId
      ? {
          order_id: orderId,
          ware_ids: array.filter((el) => el !== ''),
        }
      : {
          order_id: orderId,
          skus: array.filter((el) => el !== ''),
        };
    const response = await axios({
      method: 'post',
      url: `${OrderService.API_BASE_URL}prices/${source}`,
      data,
      headers: { 'Content-Type': 'text/plain' },
    });
    return response.data;
  };

  static changeOrderTimer = async (orderId: string, timerInfo: any) => {
    const response = await axios({
      method: 'put',
      url: `${OrderService.API_BASE_URL}orders/${orderId}/timer`,
      data: timerInfo,
    });
    return response.data;
  };
}
