import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as actions from '../../../OrdersPage/actions';
import {
  convertSeconds,
  getFromLocalStorage,
} from '../../../../global/helpers';
import * as Styled from './StatusPharmacy.styles';
import {
  DeliveryType,
  OrderTableData,
  PaymentType,
  UserRole,
  SoftType,
} from '../../../OrdersPage/types';
import { StatusRunTimer } from '../../types';
import success from '../../../../global/media/Vector (2).svg';
import CompletedConfirmationModal from '../CompletedConfirmationModal';
import { StatusType } from '../../../../global/types';
import VideoTestPage from '../../../../components/VideoTestPage';
import CustomerModalInfo from '../CustomerModalInfo/CustomerModalInfo';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import CustomCheckbox from '../../../../components/CustomCheckbox';

interface StatusPharmacyProps {
  onEdit: () => void;
  data: OrderTableData;
  setIsPharmacistOnOrderOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentFunction: any;
  setOpenQuestion: React.Dispatch<React.SetStateAction<string[]>>;
  questionsData: string[];
}

const StatusPharmacy: React.FC<StatusPharmacyProps> = ({
  onEdit,
  data,
  setIsPharmacistOnOrderOpen,
  setCurrentFunction,
  children,
  setOpenQuestion,
  questionsData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';
  const token = getFromLocalStorage('accessToken');
  const userRole = getFromLocalStorage('role');
  const education = history.location.pathname.includes('/test-orders');
  const orderTimer =
    UserRole.OPERATOR === userRole
      ? data.timer?.remainTimer
      : data.pharmacyTimer?.remainTimer;
  const orderStatusTimer =
    UserRole.OPERATOR === userRole
      ? data.timer?.statusTimer
      : data.pharmacyTimer?.statusTimer;

  const [seconds, setSeconds] = useState<number>(orderTimer || 1200);
  const [value, setValue] = useState<string>('');
  const [isOpenCompletedModal, setOpenCompletedModal] =
    useState<boolean>(false);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const [isOpenCancelOrderModal, setOpenCancelOrderModal] =
    useState<boolean>(false);
  const [allowRequestRecipe, setallowRequestRecipe] = useState<boolean>(false);
  const [recipeTooltip, serRecipeTooltip] = useState(false);
  const [isCustomerInfoModalOpen, setCustomerModalWindowOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [confirm, setConfirm] = useState({
    order: false,
    check: false,
  });

  const scrollToHashElement = () => {
    const elementToScroll = document.getElementById('questions');

    if (!elementToScroll) return;

    window.scrollTo({
      top: elementToScroll.offsetTop,
      behavior: 'smooth',
    });
  };

  const getTimer = (seconds: number) => {
    if (
      Math.abs(seconds) / 3600 > 48 ||
      data.statusPharmacy === StatusType.CANCELED ||
      data.statusPharmacy === StatusType.COMPLETED
    )
      return null;
    const getTimerColor = () => {
      if (seconds > 240 && orderTimer && orderTimer > 0) return 'timer-green';
      if (seconds <= 240 && orderTimer && orderTimer > 0) return 'timer-orange';
      if (seconds <= 0 || (orderTimer && orderTimer <= 0)) return 'timer-red';
    };
    return (
      <div className={`order-timer ${getTimerColor()}`}>
        {!value && <CircularProgress size={10} />}
        {value && orderTimer && orderTimer <= 0 ? `-${value}` : value}
      </div>
    );
  };

  useEffect(() => {
    if (Object.values(confirm).every((value) => value === true)) {
      setIsError(false);
    }
  }, [confirm]);

  useEffect(() => {
    if (
      data.sourceTags?.find(
        (tag) => tag.name === 'allow_pharmacist_recipe_request'
      )
    ) {
      setallowRequestRecipe(true);
    }
  }, [data.sourceTags]);

  useEffect(() => {
    if (orderTimer) {
      setSeconds(orderTimer);
    }
  }, [orderTimer]);

  useEffect(() => {
    let timer: any = null;
    if (seconds && orderStatusTimer === StatusRunTimer.RUNNING) {
      timer = setInterval(() => {
        setSeconds(seconds - 1);
        setValue(convertSeconds(Math.abs(seconds)));
      }, 1000);
    } else {
      setValue(convertSeconds(Math.abs(seconds)));
    }
    return () => clearInterval(timer);
  }, [seconds, orderTimer]);

  const title = (status: string) => {
    switch (status) {
      case StatusType.NEW:
        return `${t('OrderPage.Confirmation.TITLE')}`;
      case StatusType.IN_PHARMACY_PLACED:
        return `${t('OrderPage.Confirmation.TITLE')}`;
      case StatusType.ON_CLARIFICATION:
        return (
          <div className="on_clarification">
            {t('OrderPage.OrderForClarification.TITLE')}
          </div>
        );
      case StatusType.IN_PHARMACY_COLLECTING:
        return (
          <div className="collecting-block">
            <span className="collecting">
              {t('OrderPage.Collection.TITLE')}
            </span>
            {orderTimer && getTimer(seconds)}
          </div>
        );
      case StatusType.IN_PHARMACY_READY:
        return <span className="ready">{t('OrderPage.Completed.TITLE')}</span>;
      case StatusType.IN_PHARMACY_COLLECTED:
        return <span className="ready">{t('OrderPage.Collected.TITLE')}</span>;
      default:
        return data.statusNamePharmacy;
    }
  };

  const subTitle = (status: string, deliveryType: string) => {
    switch (status) {
      case StatusType.NEW:
        return `${t('OrderPage.Confirmation.SUBTITLE')}`;
      case StatusType.IN_PHARMACY_PLACED:
        return `${t('OrderPage.Confirmation.SUBTITLE')}`;
      case StatusType.IN_PHARMACY_COLLECTED:
        if (data.paymentType === PaymentType.KASPI_PAY) {
          return `${t('OrderPage.Collected.SUBTITLE')}`;
        }
        return null;
      case StatusType.ON_CLARIFICATION:
        return (
          <div className="on_clarification-block">
            <div>{t('OrderPage.OrderForClarification.SUBTITLE')}</div> <br />
          </div>
        );
      case StatusType.IN_PHARMACY_READY:
        if (data.soft === SoftType.STANDART) {
          return (
            <div className="standart-block">
              {deliveryType === DeliveryType.SELF && (
                <div>{t('OrderPage.Completed.SUBTITLE_STANDART_SELF')}</div>
              )}
              {deliveryType === DeliveryType.DELIVERY && (
                <div>{t('OrderPage.Completed.SUBTITLE_STANDART_DELIVERY')}</div>
              )}
              <br />
              <div>
                {t('OrderPage.Completed.LOADING_STANDART')}{' '}
                {data.receivedInSoft ? (
                  <img src={success} alt="success icon" />
                ) : (
                  <CircularProgress size={18} color="error" thickness={4} />
                )}
              </div>{' '}
              <br />
              {data.receivedInSoft && (
                <>
                  <div className="black">
                    {t('OrderPage.Completed.IN_CART1_STANDART')}{' '}
                    {data.orderNumber}
                  </div>
                  <div className="black">
                    {t('OrderPage.Completed.IN_CART2_STANDART')}{' '}
                  </div>
                  <br />
                </>
              )}
              <div>{t('OrderPage.Completed.VIDEO_INSTRUCTION_STANDART')}</div>
            </div>
          );
        }
        switch (deliveryType) {
          case DeliveryType.SELF:
            return (
              <div>
                <span className="ready">
                  {t('OrderPage.Completed.SUBTITLE_SELF')}
                </span>
                <br />
                <br />
                <span className="ready btn-bottom">
                  {t('OrderPage.Completed.SUBTITLE_SELF2')}
                </span>
              </div>
            );
          case DeliveryType.DELIVERY:
            return (
              <div>
                <span className="ready">
                  {t('OrderPage.Completed.SUBTITLE_DELIVERY')}
                </span>
                <br />
                <br />
                <span className="ready btn-bottom">
                  {t('OrderPage.Completed.SUBTITLE_DELIVERY2')}
                </span>
              </div>
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const handleCollectingStatusClick = () => {
    dispatch(
      actions.putCollectingStatus.request({
        id: data?.id,
        token,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              id: data.id,
              testOrders: data.test,
            })
          );
          !education && dispatch(actions.getOrders.request({ status: '' }));
        },
      })
    );
  };

  const handleReadyStatusClick = () => {
    dispatch(
      actions.putReadyStatus.request({
        id: data?.id,
        token,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              id: data.id,
              testOrders: data.test,
            })
          );
          !education && dispatch(actions.getOrders.request({ status: '' }));
        },
      })
    );
  };

  const handleCompletedStatusClick = () => {
    dispatch(
      actions.putCompletedStatus.request({
        id: data?.id,
        token,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              id: data.id,
              testOrders: data.test,
            })
          );
          !education && dispatch(actions.getOrders.request({ status: '' }));
          setOpenCompletedModal(false);
        },
      })
    );
  };

  const handleOpenCustomerModal = () => {
    serRecipeTooltip(false);
    setCustomerModalWindowOpen(true);
    dispatch(
      actions.putWaitingReceiptStatus.request({
        role,
        id: data?.id,
        token,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              id: data.id,
              testOrders: data.test,
            })
          );
          setOpenCompletedModal(false);
          !education && dispatch(actions.getOrders.request({ status: '' }));
        },
      })
    );
  };

  const handleCancelOrder = () => {
    dispatch(
      actions.putNoReceiptStatus.request({
        role,
        id: data?.id,
        token,
        postEffect: () => {
          dispatch(
            actions.setSelectedOrder.request({
              role,
              id: data.id,
              testOrders: data.test,
            })
          );
          !education && dispatch(actions.getOrders.request({ status: '' }));
          setOpenCancelOrderModal(false);
          setIsShowMore(false);
        },
      })
    );
  };

  const handleCloseCustomerModal = () => {
    serRecipeTooltip(false);
    setCustomerModalWindowOpen(false);
  };

  const confirmationButton = (
    <div>
      <div className="main-btn">
        <div
          className="assembly-btn"
          onClick={() => {
            if (
              data.sourceTags?.some(
                (el) => el.name === 'order_responsible_verification'
              )
            ) {
              setIsPharmacistOnOrderOpen(true);
              setCurrentFunction('collecting');
            } else {
              handleCollectingStatusClick();
            }
          }}
        >
          <div>{t('OrderPage.Confirmation.CONFIRM_BTN')}</div>
        </div>
        {!isShowMore && allowRequestRecipe && (
          <div className="recipe-btn" onClick={() => setIsShowMore(true)}>
            <div>{t('OrderPage.Confirmation.SHOW_MORE')}</div>
          </div>
        )}
        {isShowMore && (
          <>
            <div
              className="recipe-btn"
              onMouseEnter={() => serRecipeTooltip(true)}
              onMouseLeave={() => serRecipeTooltip(false)}
              onClick={handleOpenCustomerModal}
            >
              <div>{t('OrderPage.Confirmation.RECIPE_BTN')}</div>
            </div>
            {recipeTooltip && (
              <div
                className="recipe-tooltip"
                onMouseEnter={() => serRecipeTooltip(true)}
                onMouseLeave={() => serRecipeTooltip(false)}
              >
                <span>{t('OrderPage.Confirmation.RECIPE_TOOLTIP1')}</span>
                <span onClick={handleOpenCustomerModal} className="btn">
                  {t('OrderPage.Confirmation.BTN')}
                </span>
                <span>{t('OrderPage.Confirmation.RECIPE_TOOLTIP2')}</span>
              </div>
            )}
          </>
        )}
        <div className="edit-btn" onClick={onEdit}>
          <div>{t('OrderPage.Confirmation.SAVE_BTN')}</div>
        </div>
      </div>
      {data.statusPharmacy === StatusType.WAITING_RECEIPT && (
        <div
          className="edit-btn"
          style={{ width: '340px' }}
          onClick={() => setOpenCancelOrderModal(true)}
        >
          <div>{t('OrderPage.Confirmation.CANCEL_BTN')}</div>
        </div>
      )}
    </div>
  );

  const collectionButton = (
    <button
      className="collection-btn"
      onClick={() => {
        if (
          data.sourceTags?.some(
            (el) => el.name === 'order_responsible_verification'
          )
        ) {
          setIsPharmacistOnOrderOpen(true);
          setCurrentFunction('ready');
        } else {
          handleReadyStatusClick();
        }
      }}
      type="button"
    >
      {t('OrderPage.Collection.SAVE_BTN')}
    </button>
  );

  const collectionButtonCheckbox = (
    <div className="main-container">
      <div className="border-container">
        <p className="checkbox-text"> {t('OrderPage.Collection.CONFIRM')}</p>
        <div className="checkbox-container">
          <CustomCheckbox
            title="1.Заказ собран"
            id="order"
            onChange={() => {
              setConfirm({
                ...confirm,
                order: !confirm.order,
              });
            }}
            checked={confirm.order}
          />
          <CustomCheckbox
            title="2.Заказ отложен"
            id="check"
            onChange={() => {
              setConfirm({
                ...confirm,
                check: !confirm.check,
              });
            }}
            checked={confirm.check}
          />

          {isError && (
            <p className="error-message">
              {t('OrderPage.Collection.MUST_BE_SELECTED')}
            </p>
          )}
        </div>
      </div>
      <button
        className="collection-btn"
        onClick={() => {
          if (
            data.sourceTags?.some(
              (el) => el.name === 'order_responsible_verification'
            )
          ) {
            setIsPharmacistOnOrderOpen(true);
            setCurrentFunction('ready');
          } else {
            Object?.values(confirm)?.some((value) => value === false)
              ? setIsError(true)
              : handleReadyStatusClick();
          }
        }}
        type="button"
      >
        {t('OrderPage.Collection.BUTTON_CONFIRM')}
      </button>
    </div>
  );

  const readyButtonSelf = (
    <div className="ready-btn">
      <div className="btn" onClick={() => setOpenCompletedModal(true)}>
        <div>{t('OrderPage.Completed.SAVE_BTN')}</div>
      </div>
    </div>
  );

  const readyButtonDelivery = (
    <div className="ready-btn">
      <div className="btn" onClick={() => setOpenCompletedModal(true)}>
        <div>{t('OrderPage.Completed.SAVE_BTN')}</div>
      </div>
    </div>
  );

  return (
    <>
      <Styled.NumberCallCenter>
        {t('OrderPage.Collection.NUMBER_CALL_CENTER')}
      </Styled.NumberCallCenter>
      <Styled.StatusPharmacyContainer>
        <div id="info-block">
          <div className="title" id="info-status">
            {title(data.statusPharmacy)}
            <div
              className="helper-block"
              onClick={() => {
                setOpenQuestion(questionsData);
                scrollToHashElement();
              }}
            >
              Нажмите сюда, если есть вопросы по заказу
            </div>
          </div>
          {Boolean(subTitle(data.statusPharmacy, data.deliveryType)) &&
            data.statusPharmacy !== StatusType.IN_PHARMACY_COLLECTING && (
              <div className="subtitle" id="info-info">
                {subTitle(data.statusPharmacy, data.deliveryType)}
              </div>
            )}
        </div>

        {children}
        <div className="button-block">
          {data.statusPharmacy === StatusType.IN_PHARMACY_READY &&
            data.deliveryType === DeliveryType.SELF &&
            data.soft !== SoftType.STANDART &&
            readyButtonSelf}
          {data.statusPharmacy === StatusType.IN_PHARMACY_READY &&
            data.soft !== SoftType.STANDART &&
            data.deliveryType === DeliveryType.DELIVERY &&
            !data.sourceTags?.some(
              (el) => el.name === 'in_pharmacy_collected_issued_off'
            ) &&
            readyButtonDelivery}
        </div>
        <div className="button-block button-block-bottom">
          {(data.statusPharmacy === StatusType.NEW ||
            data.statusPharmacy === StatusType.IN_PHARMACY_PLACED ||
            data.statusPharmacy === StatusType.WAITING_RECEIPT) &&
            confirmationButton}
          {data.statusPharmacy === StatusType.IN_PHARMACY_COLLECTING &&
            !(
              data.deliveryType === DeliveryType.DELIVERY &&
              data.sourceTags?.some((el) => el.name === 'low_quallity')
            ) &&
            !data.sourceTags?.some(
              (el) => el.name === 'in_pharmacy_collected_issued_off'
            ) &&
            collectionButton}
          {data.statusPharmacy === StatusType.IN_PHARMACY_COLLECTING &&
            data.deliveryType === DeliveryType.DELIVERY &&
            data.sourceTags?.some((el) => el.name === 'low_quallity') &&
            !data.sourceTags?.some(
              (el) => el.name === 'in_pharmacy_collected_issued_off'
            ) &&
            collectionButtonCheckbox}
        </div>
      </Styled.StatusPharmacyContainer>
      {isOpenCompletedModal && (
        <CompletedConfirmationModal
          onClose={() => setOpenCompletedModal(false)}
          setStatusCompleted={handleCompletedStatusClick}
        />
      )}
      {isCustomerInfoModalOpen && (
        <CustomerModalInfo data={data} onClose={handleCloseCustomerModal} />
      )}
      {isOpenCancelOrderModal && (
        <ConfirmationModal
          onClose={() => setOpenCancelOrderModal(false)}
          onSuccess={handleCancelOrder}
          variant="cancel"
        />
      )}
      {education &&
        data.deliveryType === DeliveryType.DELIVERY &&
        data.statusPharmacy === StatusType.IN_PHARMACY_READY && (
          <VideoTestPage url="https://www.youtube.com/watch?v=-bNNYSwWZz8&ab_channel=UlanKyzyrov%28Daribar%29" />
        )}
      {education &&
        data.deliveryType === DeliveryType.SELF &&
        data.paymentType === PaymentType.KASPI_PAY &&
        data.statusPharmacy === StatusType.IN_PHARMACY_READY && (
          <VideoTestPage url="https://www.youtube.com/watch?v=T0xZMfYe_9Y&ab_channel=UlanKyzyrov%28Daribar%29" />
        )}
    </>
  );
};

export default StatusPharmacy;
