import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const ReportContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 1216px;
  margin: 40px auto;
`;

export const ReportByDaysContainer = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const InfoText = styled('div')`
  margin: 40px 0 20px 0;
  max-width: 550px;
  font-size: 18px;
  font-weight: 500;
  color: ${baseTheme.colors.primary};
`;

export const SelectOptionContainer = styled('div')`
  display: flex;
`;

export const NetworkBlock = styled('div')`
  display: flex;
  margin-bottom: 20px;
  margin-right: 55px;
  position: relative;

  .selected-network {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    word-break: break-word;
    align-items: center;
    padding: 10px 12px;
    width: 240px;
    font-weight: 400;
    font-size: 16px;
    color: ${baseTheme.colors.secondary};
    border: 1px solid ${baseTheme.colors.stroking};
    border-radius: 8px;
  }
`;

export const CalendarBlock = styled('div')``;
