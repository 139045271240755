import { LoginState } from '../containers/LoginPage/types';
import { OrdersPageState } from '../containers/OrdersPage/types';
import { InitComponentState } from '../containers/InitComponent/types';
import { OrderPageState } from '../containers/OrderPage/types';
import { OrderHistoryState } from '../containers/OrderHistoryPage/types';
import { AdminState } from '../containers/SourcesPage/types';
import { SourcePageState } from '../containers/SourcePage/types';
import { NetworkPageState } from '../containers/NetworkPage/types';
import { OperatorPageState } from '../containers/OperatorsPharmacistPage/types';
import { ReportPageState } from '../containers/ReportPage/types';
import { ActiveUsersState } from '../components/ActivePharmacistBlock/types';
import { WebStockPageReducer } from '../containers/WebStockPage/types';
import {
  AnalogsPageState,
  TemplatesPageState,
} from '../containers/PromoCodePage/types';
import { SettingsPageState } from '../containers/SettingsPage/types';

export interface AppState {
  loginReducer: LoginState;
  ordersPageReducer: OrdersPageState;
  initComponentReducer: InitComponentState;
  productsPageReducer: any;
  editPageReducer: any;
  orderPageReducer: OrderPageState;
  historyPageReducer: OrderHistoryState;
  adminPageReducer: AdminState;
  sourcePageReducer: SourcePageState;
  networkPageReducer: NetworkPageState;
  operatorsPageReducer: OperatorPageState;
  reportPageReducer: ReportPageState;
  activeUsersReducer: ActiveUsersState;
  webStockPageReducer: WebStockPageReducer;
  promoCodePageReducer: TemplatesPageState;
  analogsPageReducer: AnalogsPageState;
  settingsPageReducer: SettingsPageState;
}

export enum StatusType {
  ON_CLARIFICATION = 'on_clarification',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  NEW = 'new',
  IN_PHARMACY_COLLECTING = 'in_pharmacy_collecting',
  IN_PHARMACY_PLACED = 'in_pharmacy_placed',
  IN_PHARMACY_READY = 'in_pharmacy_ready',
  IN_PHARMACY_ISSUED = 'in_pharmacy_issued',
  IN_PHARMACY_COLLECTED = 'in_pharmacy_collected',
  IN_DELIVERY = 'in_delivery',
  WAITING_FOR_DELIVERY = 'waiting_for_delivery',
  DELIVERED = 'delivered',
  WAITING_RECEIPT = 'waiting_receipt',
  WAITING_FOR_RECEIPT = 'waiting_for_receipt',
  NO_RECEIPT = 'no_receipt',
  FOR_ADJUSTMENT = 'for_adjustment',
  GENERATE_INVOICE = 'generate_invoice',
  WAITING_FOR_DELIVERY_PAYMENT = 'waiting_for_delivery_payment',
  RETURN_PAYMENT = 'return_payment',
}

export interface ErrorMessageData {
  url: string;
  status: number | string;
  time: string | number;
  x_request_id: string;
  x_trace_id: string;
  error_message: string;
  code: string | null;
}
