import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const ManagerCollectorContainer = styled('div')`
  width: 100%;
  display: flex;
  padding-top: 36px;
`;
export const IsCollectorBlock = styled('div')`
  display: flex;
  align-items: center;

  .is-collector {
    color: ${baseTheme.colors.focus};
  }
`;
export const SourceCodesBlock = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 2px solid;
  color: ${baseTheme.colors.stroking};
  margin: 16px;
  padding: 8px;
  cursor: pointer;
  position: relative;

  img {
    margin-left: 8px;
  }

  .source-name {
    color: ${baseTheme.colors.primary};
  }
`;

export const ButtonBlock = styled('div')`
  display: flex;
  align-items: center;

  .disabled {
    pointer-events: none;
    background: ${baseTheme.colors.disabled};
    border: 2px solid ${baseTheme.colors.disabled};
  }

  div[aria-disabled] {
    padding: 6px 20px;
  }
`;

export const SourcesContainer = styled('div')`
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translate3d(0px, 10%, 0px);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }
  position: absolute;
  z-index: 600;
  width: 245px;
  background-color: ${baseTheme.colors.white};
  padding: 0 16px 16px;
  box-shadow: 0 4px 10px rgba(37, 54, 67, 0.2);
  border-radius: 8px;
  max-height: 290px;
  overflow-y: auto;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  margin: 170px 0 0 -10px;

  div {
    font-weight: 500;
    font-size: 16px;
    color: #535d6a;
    padding-top: 16px;

    &:hover {
      color: ${baseTheme.colors.mainLightBlue};
      cursor: pointer;
    }
  }
`;
