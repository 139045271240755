import { useTranslation } from 'react-i18next';
import { ProductResponseItem } from '../OrdersPage/types';
import * as constants from '../ProductsPage/constants';
import {
  CountPropsStr,
  ProductResponse,
  ProductsResult,
  TagsFieldResponse,
} from './types';

export const mappedDataForProductsPageTable = (
  dataUnAttached: any,
  dataAttached: any,
  dataBlocked: any,
  dataOnVerification: any,
  dataDybl: any,
  dataAutomapping: any
) => {
  return {
    dataUnAttached: dataUnAttached?.mappings,
    dataAttached: dataAttached?.mappings,
    dataBlocked: dataBlocked?.mappings,
    dataOnVerification: dataOnVerification?.mappings,
    dataDybl: dataDybl?.mappings,
    dataAutomapping: dataAutomapping?.mappings,
  };
};

export const mappedCount = (
  dataUnAttached: ProductsResult,
  dataAttached: ProductsResult,
  dataBlocked: ProductsResult,
  dataOnVerification: ProductsResult,
  dataDybl: ProductsResult,
  dataAutomapping: ProductsResult
) => {
  return {
    countUnAttached: Math.ceil(Number(dataUnAttached?.total_count) / 100),
    countAttached: Math.ceil(Number(dataAttached?.total_count) / 100),
    countBlocked: Math.ceil(Number(dataBlocked?.total_count) / 100),
    countOnVerification: Math.ceil(
      Number(dataOnVerification?.total_count) / 100
    ),
    countDybl: Math.ceil(Number(dataDybl?.total_count) / 100),
    countAutomapping: Math.ceil(Number(dataAutomapping?.total_count) / 100),
  };
};

export const mappedCountProducts = (
  dataUnAttached: ProductsResult,
  dataAttached: ProductsResult,
  dataBlocked: ProductsResult,
  dataOnVerification: ProductsResult,
  dataDybl: ProductsResult,
  dataAutomapping: ProductsResult
) => {
  return {
    countUnAttached:
      dataUnAttached?.total_count.toLocaleString('ru') === '0'
        ? dataUnAttached?.mappings.length.toLocaleString('ru')
        : dataUnAttached?.total_count.toLocaleString('ru'),
    countAttached:
      dataAttached?.total_count.toLocaleString('ru') === '0'
        ? dataAttached?.mappings.length.toLocaleString('ru')
        : dataAttached?.total_count.toLocaleString('ru'),
    countBlocked:
      dataBlocked?.total_count.toLocaleString('ru') === '0'
        ? dataBlocked?.mappings.length.toLocaleString('ru')
        : dataBlocked?.total_count.toLocaleString('ru'),
    countOnVerification:
      dataOnVerification?.total_count.toLocaleString('ru') === '0'
        ? dataOnVerification?.mappings.length.toLocaleString('ru')
        : dataOnVerification?.total_count.toLocaleString('ru'),
    countDybl: dataDybl?.total_count.toLocaleString('ru'),
    countAutomapping:
      dataAutomapping?.total_count.toLocaleString('ru') === '0'
        ? dataAutomapping?.mappings.length.toLocaleString('ru')
        : dataAutomapping?.total_count.toLocaleString('ru'),
  };
};

export const calculateAmountOfProducts = (
  data: ProductResponse[],
  results?: ProductResponse[]
) => {
  if (results) return results.length;
  if (!results) {
    return data === null || undefined ? '0' : data?.length;
  }
};

export const getDefaultValue = (data: any) => {
  if (data?.manufacturer === 'NaN' || !data.manufacturer) {
    return 'Не известен';
  }
  return data?.manufacturer;
};

export const useProductsDataType = (
  data: any,
  countPages: CountPropsStr,
  isOpenTab: string
) => {
  const { t } = useTranslation();
  return [
    {
      title: `${t('ProductsPage.ProductsType.AUTOMAPPING')}`,
      amount: countPages.countAutomapping,
      isActive: Boolean(isOpenTab === constants.AUTOMAPPING),
    },
    {
      title: `${t('ProductsPage.ProductsType.UNATTACHED')}`,
      amount: countPages.countUnAttached,
      isActive: Boolean(isOpenTab === constants.UNATTACHED),
    },
    {
      title: `${t('ProductsPage.ProductsType.ON_VERIFICATION')}`,
      amount: countPages.countOnVerification,
      isActive: Boolean(isOpenTab === constants.VERIFICATION),
    },
    {
      title: `${t('ProductsPage.ProductsType.ATTACHED')}`,
      amount: countPages.countAttached,
      isActive: Boolean(isOpenTab === constants.ATTACHED),
    },
    {
      title: `${t('ProductsPage.ProductsType.BLOCKED')}`,
      amount: countPages.countBlocked,
      isActive: Boolean(isOpenTab === constants.BLOCKED),
    },
    {
      title: `${t('ProductsPage.ProductsType.DYBL')}`,
      amount: countPages.countDybl,
      isActive: Boolean(isOpenTab === constants.DYBL),
    },
  ];
};

export const paginationPage = (number: number) => {
  if (number <= 0) {
    return 0;
  }
  return number;
};

export const mappedResponseWithSkuName = (
  data: any,
  dataSkus?: ProductResponseItem[]
) => {
  return {
    ...data,
    mappings: data.mappings?.map((el: any) => {
      return {
        ...el,
        skuName: el.product_name || '',
        skuManufacturer: el.product_manufacturer || '',
        urlKey: el.product_url || '',
      };
    }),
  };
};

export const mappedResponseSearchWithSkuName = (
  data: any,
  dataSkus?: ProductResponseItem[]
) => {
  return data.map((el: any) => {
    return {
      ...el,
      skuName: el.product_name || '',
      skuManufacturer: el.product_manufacturer || '',
      urlKey: el.product_url || '',
    };
  });
};

export const mappedTags = (data: TagsFieldResponse[] | null) => {
  return data?.map((el) => {
    return {
      tagName: el.name,
      tagColor: el.color || '',
      tagRequiredMeta: el.required_meta || false,
      tagId: el.id.toString(),
      allowed: el.allowed || false,
    };
  });
};

export const getTagsRowForService = (tags: string[]) => {
  return tags.map((el) => `tag=${el}`).join('&');
};
