import { createReducer } from '@reduxjs/toolkit';
import { OrderPageState } from './types';
import * as actions from './actions';
import { AppState } from '../../global/types';

const initialState: OrderPageState = {
  postImg: {
    loading: false,
    data: null,
    error: null,
  },
  productsSearchForAnalog: {
    data: null,
    loading: false,
    error: null,
  },

  putOrder: {
    data: null,
    loading: false,
    error: null,
  },

  price: {
    data: null,
    loading: false,
    error: null,
  },

  allStatuses: {
    data: null,
    loading: false,
    error: null,
  },

  allPharmacyStatuses: {
    data: null,
    loading: false,
    error: null,
  },

  priceWithQuantity: {
    data: null,
    loading: false,
    error: null,
  },

  promoCode: {
    data: null,
    loading: false,
    error: null,
  },

  selectedProduct: null,
  selectedActivePharmacistOnOrder: null,
};

const reducer = createReducer(initialState, {
  [actions.postImg.REQUEST]: (state) => {
    state.postImg.loading = true;
  },
  [actions.postImg.SUCCESS]: (state, action) => {
    state.postImg.loading = false;
    state.postImg.data = action.payload;
  },
  [actions.postImg.ERROR]: (state, action) => {
    state.postImg.loading = false;
    state.postImg.error = action.payload;
  },
  [actions.putOrder.REQUEST]: (state) => {
    state.putOrder.loading = true;
  },
  [actions.putOrder.SUCCESS]: (state, action) => {
    state.putOrder.loading = false;
    state.putOrder.data = action.payload;
  },
  [actions.putOrder.ERROR]: (state, action) => {
    state.putOrder.loading = false;
    state.putOrder.error = action.payload.response.data;
  },
  [actions.applyPromoCode.REQUEST]: (state) => {
    state.promoCode.loading = true;
  },
  [actions.applyPromoCode.SUCCESS]: (state, action) => {
    state.promoCode.loading = false;
    state.promoCode.data = action.payload;
  },
  [actions.applyPromoCode.ERROR]: (state, action) => {
    state.promoCode.loading = false;
    state.promoCode.error = action.payload.response.data;
  },
  [actions.getProductsSearchForAnalog.REQUEST]: (state) => {
    state.productsSearchForAnalog.loading = true;
  },
  [actions.getProductsSearchForAnalog.ERROR]: (state, action) => {
    state.productsSearchForAnalog.error = action.payload;
    state.productsSearchForAnalog.loading = false;
  },
  [actions.getProductsSearchForAnalog.SUCCESS]: (state, action) => {
    state.productsSearchForAnalog.data = action.payload;
    state.productsSearchForAnalog.loading = false;
  },
  [actions.setSelectedProduct.type]: (state, action) => {
    state.selectedProduct = action.payload;
  },
  [actions.setSelectedActivePharmacistOnOrder.type]: (state, action) => {
    state.selectedActivePharmacistOnOrder = action.payload;
  },
  [actions.clearSelectedProduct.type]: (state) => {
    state.selectedProduct = null;
  },
  [actions.clearSelectedActivePharmacistOnOrder.type]: (state) => {
    state.selectedActivePharmacistOnOrder = null;
  },
  [actions.getPrice.REQUEST]: (state) => {
    state.price.loading = true;
  },
  [actions.getPrice.ERROR]: (state, action) => {
    state.price.error = action.payload;
    state.price.loading = false;
  },
  [actions.getPrice.SUCCESS]: (state, action) => {
    state.price.data = action.payload;
    state.price.loading = false;
  },
  [actions.getPriceWithQuantityBySource.REQUEST]: (state) => {
    state.priceWithQuantity.loading = true;
  },
  [actions.getPriceWithQuantityBySource.ERROR]: (state, action) => {
    state.priceWithQuantity.error = action.payload;
    state.priceWithQuantity.loading = false;
  },
  [actions.getPriceWithQuantityBySource.SUCCESS]: (state, action) => {
    state.priceWithQuantity.data = action.payload;
    state.priceWithQuantity.loading = false;
  },
  [actions.getAllStatuses.REQUEST]: (state) => {
    state.allStatuses.loading = true;
  },
  [actions.getAllStatuses.ERROR]: (state, action) => {
    state.allStatuses.error = action.payload;
    state.allStatuses.loading = false;
  },
  [actions.getAllStatuses.SUCCESS]: (state, action) => {
    state.allStatuses.data = action.payload;
    state.allStatuses.loading = false;
  },
  [actions.getAllPharmacyStatuses.REQUEST]: (state) => {
    state.allPharmacyStatuses.loading = true;
  },
  [actions.getAllPharmacyStatuses.ERROR]: (state, action) => {
    state.allPharmacyStatuses.error = action.payload;
    state.allPharmacyStatuses.loading = false;
  },
  [actions.getAllPharmacyStatuses.SUCCESS]: (state, action) => {
    state.allPharmacyStatuses.data = action.payload;
    state.allPharmacyStatuses.loading = false;
  },
});

const productsSearchLoading = (state: AppState) =>
  state.orderPageReducer.productsSearchForAnalog.loading;
const productsSearchError = (state: AppState) =>
  state.orderPageReducer.productsSearchForAnalog.error;
const productsSearchData = (state: AppState) =>
  state.orderPageReducer.productsSearchForAnalog.data;

const recipeImg = (state: AppState) => state.orderPageReducer.postImg.data;
const recipeImgLoading = (state: AppState) =>
  state.orderPageReducer.postImg.loading;

const analogPrice = (state: AppState) => state.orderPageReducer.price.data;
const priceWithQuantityData = (state: AppState) =>
  state.orderPageReducer.priceWithQuantity.data;

const skuSelectedProduct = (state: AppState) =>
  state.orderPageReducer.selectedProduct;

const promoCode = (state: AppState) => state.orderPageReducer.promoCode;

const allStatuses = (state: AppState) => {
  return state.orderPageReducer.allStatuses.data?.internal_statuses;
};

const deliveryStatuses = (state: AppState) => {
  return state.orderPageReducer.allStatuses.data?.claim_statuses;
};

const allPharmacyStatuses = (state: AppState) =>
  state.orderPageReducer.allPharmacyStatuses.data?.internal_statuses;

const selectedActivePharmacist = (state: AppState) =>
  state.orderPageReducer.selectedActivePharmacistOnOrder;

const editError = (state: AppState) => state.orderPageReducer.putOrder.error;

const selectors = {
  recipeImg,
  recipeImgLoading,
  productsSearchError,
  productsSearchLoading,
  productsSearchData,
  skuSelectedProduct,
  analogPrice,
  priceWithQuantityData,
  allStatuses,
  allPharmacyStatuses,
  editError,
  selectedActivePharmacist,
  deliveryStatuses,
  promoCode,
};

export { selectors };
export default reducer;
