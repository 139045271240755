import styled from 'styled-components';

export const Wrapper = styled('div')`
  max-height: 60vh;
  max-width: 500px;
  .button {
    border: none;
    cursor: pointer;
    background: #0a77c7;
    border-radius: 4px;
    padding: 10px 24px;
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    &:hover {
      transition: 200ms;
      background-color: #003e6e;
    }
  }
`;

export const ContactWrapper = styled('div')`
  .title {
    font-size: 20px;
    font-weight: bold;
    margin: 16px 0;
    width: 100%;
    text-align: center;
  }
`;
