import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const PharmacistContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
`;

export const PharmacistBlock = styled('div')`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 32px;
  width: 100%;
  min-width: 846px;
`;

export const ChangedButtonBlock = styled('div')`
  max-width: 300px;
  margin-top: 24px;

  position: relative;

  .error-tooltip {
    width: 300px !important;
    word-break: break-word;
    background-color: #f6f9ff;
    filter: drop-shadow(0px 1px 8px rgba(0, 58, 136, 0.15));
    border-radius: 4px;
    color: ${baseTheme.colors.secondary};
    font-size: 16px;
    font-weight: 400;
    padding: 8px 16px;
    position: absolute;
    top: 55px;
    left: -60px;
  }
`;

export const PharmacistBlockTitle = styled('div')`
  font-weight: 700;
  font-size: 36px;
  color: ${baseTheme.colors.primary};
  padding-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;

  span {
    color: ${baseTheme.colors.focus} !important;
  }

  div {
    font-size: 18px;
    color: ${baseTheme.colors.secondary};
    padding-top: 8px;
  }
`;

export const EmptyActiveButton = styled('div')``;
