import styled from 'styled-components';
import { baseTheme } from '../../../../global/styles/theme';

export const DetailLeftBlock = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: white;
  background-color: rgba(10, 119, 199, 0.05);
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 0 0 8px 8px;

  .notification-delivery {
    left: -8px;
    position: fixed;
    font-size: 24px;
    font-weight: 700;
    color: #000;
    transform: rotate(90deg);
    transform-origin: top left;
    margin-left: 4rem;
    background-color: rgb(255, 255, 102);
    width: 100vh;
    height: 55px;
    padding: 10px;
    padding-left: 20px;
  }
`;

export const OrderGeneralInfo = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;
  width: 260px;
  margin: 0 15px 20px 15px;
  background-color: ${baseTheme.colors.white};
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 0 0 8px 8px;

  .order-number {
    font-size: 24px;
    font-weight: 700;
    color: ${baseTheme.colors.primary};
    margin-bottom: 12px;
  }

  .creation-date {
    font-size: 16px;
    font-weight: 400;
    color: ${baseTheme.colors.secondary};
    margin-bottom: 10px;
  }

  .phone-number {
    color: ${baseTheme.colors.primary};
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;

    .phone-copy-icon {
      margin-left: 5px;
      cursor: pointer;
    }

    span {
      color: ${baseTheme.colors.secondary};
      font-weight: 400;
      padding-right: 4px;
    }
  }

  .general-info__title-option-reissue {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 700;
    color: ${baseTheme.colors.primary};
  }

  .arrow {
    width: 12px;
  }

  .arrow-top {
    width: 12px;
    transform: rotate(180deg);
  }

  .reissuedOrder {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .reissuedOrder_number {
    color: ${baseTheme.colors.mainLightBlue};
    font-size: 17px;
    font-weight: 700;
    margin-right: 15px;
  }
  .color {
    color: ${baseTheme.colors.primary};
    margin-left: 10px;
  }
`;

export const OrderDetailInfo = styled('div')`
  width: 260px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  margin: 0px 15px 20px;
  background-color: ${baseTheme.colors.white};
  border: 1px solid ${baseTheme.colors.stroking};
  border-radius: 8px;

  .delivery-option__title {
    font-size: 16px;
    font-weight: 400;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .delivery-option__value {
    font-weight: 700 !important;
  }

  .delivery-option-block {
    padding: 5px;
    border-radius: 10px;
    border: 1px solid #e0e5eb;
    margin-bottom: 10px;
  }

  .delivery-option__another-options {
    width: 100%;
  }

  .hide {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    margin-top: -7px;
    margin-left: auto;
    font-weight: 700;
    color: #eb5757;
    font-size: 14px;
  }

  .details-info__title {
    font-size: 18px;
    font-weight: 500;
    color: ${baseTheme.colors.primary};
    margin-bottom: 16px;
  }

  .details-info__title-promo {
    font-size: 16px;
    font-weight: 700;
    color: #535d6a;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .details-info__title-main {
    font-size: 18px;
    font-weight: 700;
    color: ${baseTheme.colors.primary};
    margin-bottom: 16px;
  }

  .details-info__title-option-delivery {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 7px;
    border-radius: 5px;
    border: solid 1px #0a77c7;
    font-size: 15px;
    font-weight: 700;
    color: ${baseTheme.colors.primary};
  }

  .wa-number {
    margin-top: 20px;
  }

  .input {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .wa-editor-container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .error-message {
    margin: 0;
    color: #eb5757;
  }

  .error-message-promo {
    margin: 0;
    color: #eb5757;
    font-size: 12px;
  }

  .pharmacy-tags {
    .title {
      font-size: 18px;
      font-weight: 500;
      color: ${baseTheme.colors.primary};
      margin-bottom: 16px;
      margin-top: 0px;
      margin-bottom: 5px;
    }

    &__container {
      &__item {
        width: 100%;
        display: block;
        border-bottom: 1px solid ${baseTheme.colors.disabled};
        padding-bottom: 5px;

        p {
          color: ${baseTheme.colors.secondary};
        }
      }

      p {
        margin: 0px;
        hyphens: auto;
      }
    }
  }

  .delivery-type__value-price {
    text-decoration: line-through;
  }

  .delivery-type__title-accent {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .delivery-type__title-grey {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #535d6a;
    margin-left: 5px;
  }

  .delivery-type {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .delivery-type__title {
      font-size: 16px;
      font-weight: 400;
      color: ${baseTheme.colors.secondary};
    }

    .delivery-type__value {
      padding-top: 8px;
    }
  }

  .apply-promo-code {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    padding: 6px;
    border-radius: 8px;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }
  .flex-container {
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .input-promocode {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 165px;
  }

  .input-promocode-error {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 165px;
    color: #eb5757;
  }

  .delivery-type__value-red {
    color: #eb5757;
    font-weight: 700;
  }

  .details-info__title-promo-red {
    font-size: 16px;
    color: #eb5757;
    font-weight: 400;
  }

  .delivery-type__value-green {
    color: rgb(31, 160, 105);
  }

  .delivery-type__description-green {
    text-align: end;
    color: rgb(31, 160, 105);
  }

  .delivery-type__yandex {
    animation-duration: 1s;
    animation-name: slidein-yellow;
    animation-iteration-count: infinite;

    @keyframes slidein-yellow {
      0% {
        color: #000;
        background-color: yellow;
      }

      50% {
        background-color: ${baseTheme.colors.white};
        color: #000;
      }

      100% {
        color: #000;
        background-color: yellow;
      }
    }

    padding: 5px;
    border-radius: 5px;
  }
  .delivery-type__choco {
    animation-duration: 1s;
    animation-name: slidein-red;
    animation-iteration-count: infinite;

    @keyframes slidein-red {
      0% {
        color: #fff;
        background-color: red;
      }

      50% {
        background-color: ${baseTheme.colors.white};
        color: #000;
      }

      100% {
        color: #fff;
        background-color: red;
      }
    }

    padding: 5px;
    border-radius: 5px;
  }

  .delivery-type__value-accent {
    margin-bottom: 10px;
    padding-top: 8px;
    color: rgb(31, 160, 105);
    font-weight: 700;
  }

  .delivery-type__value-accent-price {
    margin-bottom: 10px;
    color: rgb(31, 160, 105);
  }

  .arrow {
    width: 12px;
  }

  .arrow-top {
    width: 12px;
    transform: rotate(180deg);
  }

  .pharmacy {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    max-width: 200px;
    word-wrap: break-word;
    margin-top: 10px;

    .pharmacy__title {
      font-size: 16px;
      font-weight: 400;
      color: ${baseTheme.colors.secondary};
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  .re-register-btn {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-size: 14px;
    width: 150px;
    padding: 6px 19px;
    border-radius: 8px;
    margin-bottom: 10px;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }

  .cancel_button {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${baseTheme.colors.mainLightBlue};
    color: ${baseTheme.colors.white};
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    padding: 6px;
    border-radius: 8px;
    margin-bottom: 10px;

    &:hover {
      background-color: ${baseTheme.colors.mainBlue};
    }
  }

  .disabled {
    pointer-events: none;
    background: ${baseTheme.colors.disabled};
    border: 2px solid ${baseTheme.colors.disabled};
  }

  .detail {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .detail__title {
      font-size: 16px;
      font-weight: 400;
      color: ${baseTheme.colors.secondary};
      margin-right: 8px;
      margin-bottom: 8px;
    }

    div {
      color: ${baseTheme.colors.warning};
      font-weight: 500;
    }
  }

  .pin-recipe {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .recipe__title {
      font-size: 16px;
      font-weight: 400;
      color: ${baseTheme.colors.secondary};
      margin-right: 8px;
      display: flex;

      img {
        padding-left: 11px;
        cursor: pointer;
      }
    }

    .file-name {
      padding-top: 8px;
      font-weight: 600;
      max-width: 200px;
      word-wrap: break-word;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .address__title {
      font-size: 16px;
      font-weight: 400;
      color: ${baseTheme.colors.secondary};
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  .comment {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .comment__title {
      font-size: 16px;
      font-weight: 400;
      color: ${baseTheme.colors.secondary};
      margin-right: 8px;
      margin-bottom: 8px;
    }

    &__message {
      word-wrap: break-word;
    }
  }

  .payment-type {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    img {
      margin: 0 10px -4px 0;
    }

    .payment-type__title {
      font-size: 16px;
      font-weight: 700;
      color: ${baseTheme.colors.secondary};
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .payment-type__value {
      font-weight: 700;
      color: ${baseTheme.colors.primary};
    }

    .payment-true-block {
      display: flex;
      flex-direction: column;
      font-weight: 700 !important;

      .was_paid_at {
        padding-top: 5px;
        font-weight: 500 !important;
        font-size: 14px;
        color: ${baseTheme.colors.secondary};
      }
    }
  }
`;
