import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import * as Styled from './Timer.styles';
import CustomTextarea from '../../../../components/CustomTextarea';
import Button from '../../../../components/Button';
import { OrderTableData } from '../../../OrdersPage/types';
import * as actions from '../../actions';
import { formatOrderTimer, formatOrderHours } from '../../helpers';
import {
  convertSeconds,
  formatTimeForTimer,
  getFromLocalStorage,
} from '../../../../global/helpers';
import {
  StatusDay,
  StatusRunTimer,
  StatusTimer,
  TimerTypeOptions,
} from '../../types';
import * as constants from '../../constants';
import * as ordersActions from '../../../OrdersPage/actions';

interface TimerProps {
  data: OrderTableData;
  messageData: string[] | [];
}

const Timer = ({ data, messageData }: TimerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role =
    getFromLocalStorage('role') === 'manager' ? 'network' : 'pharmacy';
  const [comment, setComment] = useState<string>('');
  const [userComment, setUserComment] = useState<string>('');
  const [errorTooltip, setErrorTooltip] = useState<boolean>(false);
  const [timer, setTimer] = useState<string>(constants.DEFAULT_TIME);
  const [activeStatus, setActiveStatus] = useState<string>(StatusTimer.TIMER);
  const [activeDay, setActiveDay] = useState<string>(StatusDay.TODAY);
  const [value, setValue] = useState<string>('');
  const [newTimer, setNewTimer] = useState<number | null>(0);
  const [seconds, setSeconds] = useState<number>(
    data.timer?.remainTimer || 1200
  );
  const [isChecked, setIsChecked] = useState(false);
  const [verification, setVerification] = useState<boolean>(false);
  const [timerUserCommentType, setTimerUserCommentType] = useState<
    null | string
  >(null);
  const firstUserCommentOption = messageData.length > 0 ? messageData[0] : '';
  const secondUserCommentOption = messageData.length > 1 ? messageData[1] : '';
  const thirdUserCommentOption = messageData.length > 2 ? messageData[2] : '';

  const getUserMessageText = () => {
    if (timerUserCommentType === firstUserCommentOption) {
      return firstUserCommentOption;
    }
    if (timerUserCommentType === secondUserCommentOption) {
      return secondUserCommentOption;
    }
    if (timerUserCommentType === thirdUserCommentOption) {
      return thirdUserCommentOption;
    }
    if (
      timerUserCommentType === TimerTypeOptions.comment &&
      userComment.length > 0
    ) {
      return userComment;
    }
    if (timerUserCommentType === TimerTypeOptions.notСhosen) {
      return null;
    }

    return null;
  };

  const needVerification = () => {
    if (
      (data.status === 'in_pharmacy_placed' ||
        data.status === 'waiting_for_payment' ||
        data.status === 'calculate_delivery' ||
        data.status === 'waiting_for_delivery_payment') &&
      !data.whatsappNumber
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setVerification(needVerification());
  }, [data]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setComment(e.target.value);
  };

  const handleStart = () => {
    if (comment) {
      dispatch(
        actions.changeTimer.request({
          orderId: data.orderNumber.slice(1),
          orderTimer:
            // eslint-disable-next-line
            activeStatus === StatusTimer.TIMER
              ? formatOrderTimer(comment, newTimer, getUserMessageText())
              : activeDay === StatusDay.TODAY
              ? formatOrderHours(comment, formatTimeForTimer(timer.split(':')))
              : formatOrderHours(
                  comment,
                  formatTimeForTimer(timer.split(':'), true),
                  getUserMessageText()
                ),
          postEffect: () => {
            dispatch(
              ordersActions.setSelectedOrder.request({ role, id: data.id })
            );
            dispatch(ordersActions.getOrders.request({ status: '' }));
            setTimer(constants.DEFAULT_TIME);
            setComment('');
            setErrorTooltip(false);
          },
        })
      );
    } else {
      setErrorTooltip(true);
    }
  };

  useEffect(() => {
    if (data.timer?.remainTimer) {
      setSeconds(data.timer?.remainTimer);
    }
  }, [data.timer?.remainTimer]);

  useEffect(() => {
    let timer: any = null;
    if (seconds && data.timer?.statusTimer === StatusRunTimer.RUNNING) {
      timer = setInterval(() => {
        setValue(convertSeconds(Math.abs(seconds)));
        setSeconds(seconds - 1);
      }, 1000);
    } else {
      setValue(convertSeconds(Math.abs(seconds)));
    }
    return () => clearInterval(timer);
  }, [seconds, data.timer?.remainTimer]);

  const getInterval = (start: any, end: any) => {
    let interval = end - start;
    interval /= 1000;

    const day = Math.round(interval / 60 / 60 / 24);
    const hour = Math.round((interval / 60 / 60) % 24);
    const minute = Math.round((interval / 60) % 60);
    const second = Math.round(interval % 60);
    return {
      day,
      hour,
      minute,
      second,
    };
  };

  const handleChangeValue = (e: string) => {
    setTimer(e);
    if (activeStatus === StatusTimer.TIMER) {
      const a = e.split(':');
      const seconds = +a[0] * 60 * 60 + +a[1] * 60;
      setNewTimer(seconds);
    } else if (activeDay === StatusDay.TOMORROW) {
      const interVal = getInterval(
        data.timer?.localTime && new Date(data.timer?.localTime).getTime(),
        new Date(formatTimeForTimer(timer.split(':'), true)).getTime()
      );
      const seconds = +interVal.hour * 60 * 60 + +interVal.minute * 60;
      setNewTimer(seconds);
    } else {
      const interVal = getInterval(
        data.timer?.localTime && new Date(data.timer?.localTime).getTime(),
        new Date(formatTimeForTimer(timer.split(':'))).getTime()
      );
      const seconds = +interVal.hour * 60 * 60 + +interVal.minute * 60;
      setNewTimer(seconds);
    }
  };

  const handleChangeStatus = (status: string) => {
    setActiveStatus(status);
    setTimer(constants.DEFAULT_TIME);
  };

  const handleChangeDay = (status: string) => {
    setActiveDay(status);
    setTimer(constants.DEFAULT_TIME);
  };

  const getTimer = (seconds: number) => {
    if (Math.abs(seconds) / 3600 > 48) return null;
    const getTimerColor = () => {
      if (
        seconds > 600 &&
        data.timer?.remainTimer &&
        data.timer?.remainTimer > 0
      )
        return 'timer-green';
      if (
        seconds <= 240 &&
        data.timer?.remainTimer &&
        data.timer?.remainTimer > 0
      )
        return 'timer-orange';
      if (
        seconds < 0 ||
        (data.timer?.remainTimer && data.timer?.remainTimer <= 0)
      )
        return 'timer-red';
    };
    return (
      <div className={`order-timer ${getTimerColor()}`}>
        {!value && <CircularProgress size={10} />}
        {value && data.timer?.remainTimer && data.timer?.remainTimer <= 0
          ? `-${value}`
          : value}
      </div>
    );
  };

  return (
    <Styled.TimerContainer>
      <Styled.Timer>
        <div className="timer">
          {data.timer?.remainTimer && getTimer(seconds)}
        </div>
        {data.timer?.commentTimer && (
          <div className="timer-comment">{data.timer?.commentTimer}</div>
        )}
        {data.timer?.userMessage && (
          <div className="user-comment">
            Пользовательское сообщение: <span>{data.timer?.userMessage}</span>
          </div>
        )}
      </Styled.Timer>
      <Styled.TimerBlock>
        <Styled.ChangeStatusBlock>
          <div
            className={`status ${
              activeStatus === StatusTimer.TIMER && 'active'
            }`}
            onClick={() => handleChangeStatus(StatusTimer.TIMER)}
          >
            {' '}
            {t('OrderPage.TimerOperator.TIMER_STATUS')}
          </div>
          <div
            className={`status ${
              activeStatus === StatusTimer.HOUR && 'active'
            }`}
            onClick={() => handleChangeStatus(StatusTimer.HOUR)}
          >
            {' '}
            {t('OrderPage.TimerOperator.HOUR_STATUS')}
          </div>
        </Styled.ChangeStatusBlock>
        <Styled.SetTimer>
          <div className="timer">
            <input
              type="time"
              value={timer}
              onChange={(e) => handleChangeValue(e.target.value)}
            />
          </div>
          {activeStatus === StatusTimer.HOUR && (
            <div className="days-block">
              <div
                className={`day ${activeDay === StatusDay.TODAY && 'active'}`}
                onClick={() => handleChangeDay(StatusDay.TODAY)}
              >
                {' '}
                {t('OrderPage.TimerOperator.TODAY')}
              </div>
              <div
                className={`day ${
                  activeDay === StatusDay.TOMORROW && 'active'
                }`}
                onClick={() => handleChangeDay(StatusDay.TOMORROW)}
              >
                {' '}
                {t('OrderPage.TimerOperator.TOMORROW')}
              </div>
            </div>
          )}
        </Styled.SetTimer>
        <div className="timer-comment-container">
          <div className="wrapper">
            <div className="container-textarea">
              <CustomTextarea
                onChange={(e) => setComment(e)}
                value={comment}
                placeholder={t('OrderPage.TimerOperator.COMMENT_PLACEHOLDER')}
              />
            </div>
            <select
              onChange={handleChange}
              value={comment}
              className="select-group"
            >
              <option value="">
                {t('OrderPage.TimerOperator.COMMENT_PLACEHOLDER')}
              </option>
              <option value="Аптека собирает заказ">
                {t('OrderPage.TimerOperator.PHARMACY_IS_PREPARING_THE_ORDER')}
              </option>
              <option value="Ожидаем оплату за заказ">
                {t('OrderPage.TimerOperator.AWAITING_PAYMENT_FOR_THE_ORDER')}
              </option>
              <option value="Ожидаем оплату за доставку">
                {t('OrderPage.TimerOperator.AWAITING_PAYMENT_FOR_DELIVERY')}
              </option>
              <option value="Ожидаем ответ от аптеки">
                {t(
                  'OrderPage.TimerOperator.AWAITING_RESPONSE_FROM_THE_PHARMACY'
                )}
              </option>
            </select>
          </div>

          {verification && (
            <div className="checkbox-container">
              <input
                id="checkboxId"
                className="checkbox-input"
                type="checkbox"
                onChange={handleCheckboxChange}
                checked={isChecked}
              />
              <label htmlFor="checkboxId" className="label-checkbox">
                <p>{t('OrderPage.TimerOperator.CLIENT_IS_NOTIFIED')}</p>
              </label>
            </div>
          )}

          <div className="radio-box-container">
            <p>{t('OrderPage.TimerOperator.USER_MESSAGE_TITLE')}</p>
            {data?.whatsappNumber ? (
              <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                defaultValue={TimerTypeOptions.notСhosen}
                row
                name="radio-buttons-group"
                onChange={(e) => {
                  setTimerUserCommentType(e.target.value);
                }}
              >
                <>
                  <FormControlLabel
                    value="notСhosen"
                    control={<Radio />}
                    label={
                      t(
                        'OrderPage.TimerOperator.USER_MESSAGE_OPTIONS.WITHOUT_MESSAGE'
                      ) as string
                    }
                  />
                  {firstUserCommentOption && (
                    <FormControlLabel
                      value={firstUserCommentOption}
                      control={<Radio />}
                      label={
                        t(
                          'OrderPage.TimerOperator.USER_MESSAGE_OPTIONS.FIRST_TEMPLATE'
                        ) as string
                      }
                    />
                  )}
                  {secondUserCommentOption && (
                    <FormControlLabel
                      value={secondUserCommentOption}
                      control={<Radio />}
                      label={
                        t(
                          'OrderPage.TimerOperator.USER_MESSAGE_OPTIONS.SECOND_TEMPLATE'
                        ) as string
                      }
                    />
                  )}
                  {thirdUserCommentOption && (
                    <FormControlLabel
                      value={thirdUserCommentOption}
                      control={<Radio />}
                      label={
                        t(
                          'OrderPage.TimerOperator.USER_MESSAGE_OPTIONS.THIRD_TEMPLATE'
                        ) as string
                      }
                    />
                  )}
                  <FormControlLabel
                    value="comment"
                    control={<Radio />}
                    label={
                      t(
                        'OrderPage.TimerOperator.USER_MESSAGE_OPTIONS.COMMENT'
                      ) as string
                    }
                  />

                  {timerUserCommentType === TimerTypeOptions.comment && (
                    <CustomTextarea
                      onChange={(e) => setUserComment(e)}
                      value={userComment}
                      placeholder={
                        t(
                          'OrderPage.TimerOperator.USER_MESSAGE_PLACEHOLDER'
                        ) as string
                      }
                    />
                  )}
                </>
              </RadioGroup>
            ) : (
              <div>
                {t('OrderPage.TimerOperator.NOT_AVAILABLE_SEND_MESSAGE')}
              </div>
            )}
          </div>
          <Styled.StartButton>
            {errorTooltip && (
              <div className="error-tooltip">
                {t('OrderPage.TimerOperator.ERROR_TOOLTIP')}
              </div>
            )}

            <button
              type="button"
              className="start-timer"
              disabled={
                timer === constants.DEFAULT_TIME ||
                timer === '' ||
                (!isChecked && verification)
              }
              onClick={handleStart}
            >
              {t('OrderPage.TimerOperator.START_TIMER')}
            </button>
          </Styled.StartButton>
        </div>
      </Styled.TimerBlock>
    </Styled.TimerContainer>
  );
};

export default Timer;
