export interface OrderHistoryEvent {
  created_at: string;
  current: string;
  event: string;
  event_type: string;
  has_problem: boolean;
  item_id: string;
  phone: string;
  role: string;
  previous: string;
  collector?: string;
}

export interface OrderHistoryResponse {
  result: {
    count_problems: number;
    items: OrderHistoryEvent[];
  };
  status: string;
}

export interface OrderHistoryMappedResponse {
  eventType: string;
  event: string;
  createdAt: string;
  diffMsTimer: number;
  phone: string;
  role: string;
  collector: string;
  previousTimer: string;
  currentTimer: string;
  commentTimerCurrent: string;
  commentTimerPrevious: string;
  previousPaymentMethod: string | null;
  previousDeliveryMethod: string | null;
  currentPaymentMethod: string | null;
  currentDeliveryMethod: string | null;
  previousChangedPaymentMethod: string | null;
  currentChangedPaymentMethod: string | null;
  previousChangedDeliveryPaymentMethod: string | null;
  currentChangedDeliveryPaymentMethod: string | null;
  previousStatus: {
    code: string;
    name: string;
    color: string;
  } | null;
  currentStatus: {
    code: string;
    name: string;
    color: string;
  } | null;
  currentPharmacy: string | null;
  previousPharmacy: string | null;
}

export interface OrderHistoryState {
  historyPage: {
    data: OrderHistoryMappedResponse[] | null;
    loading: boolean;
    error: null | string;
  };
}

export enum EventType {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum Event {
  CHANGE_STATUS = 'changed_status',
  CHANGE_STATUS_PHARMACY = 'changed_pharmacy_status',
  CREATE_ORDER = 'create_order',
  CHANGE_DELIVERY = 'changed_delivery_method',
  UPDATE_TIMER = 'update_timer',
  UPDATE_TIMER_COMMENT = 'update_timer_comment',
  CHANGED_PAYMENT_METHOD = 'changed_payment_method',
  CHANGED_DELIVERY_METHOD = 'changed_delivery_method',
  CHANGED_USER_ADDRESS = 'changed_user_address',
  CHANGED_RECEIPT = 'changed_receipt',
  APPEND_ITEM = 'append_item',
  CHANGED_ITEM_QUANTITY = 'changed_item_quantity',
  CHANGED_ITEM_PHARMACY_COUNT = 'changed_item_pharmacy_count',
  CREATE_PROPOSAL = 'create_proposal',
  DELETE_PROPOSAL = 'delete_proposal',
  CHANGED_PROPOSAL_COUNT = 'changed_proposal_count',
  CHANGED_PROPOSAL_PHARMACY_COUNT = 'changed_proposal_pharmacy_count',
  DELETE_PROPOSAL_ANALOG = 'changed_proposal_analog',
  DELETE_PROPOSAL_WARE_ID = 'changed_proposal_ware_id',
  CHANGED_SOURCE = 'changed_source',
  CHANGED_DELIVERY_PAYMENT_STATUS = 'changed_delivery_payment_status',
  CHANGED_ORDER_PAYMENT_STATUS = 'changed_order_payment_status',
}
