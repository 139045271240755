import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;

  .closed-order-manager {
    padding-top: 4px;
    color: ${baseTheme.colors.primary};
    word-break: break-word;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 4px;
    max-width: 370px;
  }

  .closed-order {
    padding-top: 4px;
    color: ${baseTheme.colors.primary};
    word-break: break-word;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    max-width: 570px;
  }

  .city-price-block {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 4px 5px;
    border-top: 1.5px solid ${baseTheme.colors.secondary};
    border-bottom: 1.5px solid ${baseTheme.colors.secondary};

    .accordion-button {
      font-weight: 700;
      font-size: 18px;
      color: ${baseTheme.colors.focus};
    }

    .city {
      font-weight: 500;
      font-size: 18px;
      color: ${baseTheme.colors.secondary};
    }

    .city-price {
      padding-left: 8px;
      font-weight: 700;
      font-size: 18px;
      color: ${baseTheme.colors.focus};
    }
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }

  .quantity-block {
    margin: 4px 0 16px;
    display: flex;
    .title {
      font-weight: 500;
      font-size: 18px;
      color: ${baseTheme.colors.secondary};
      padding-right: 8px;
    }
    .closed-amount {
      font-weight: 700;
      font-size: 18px;
      color: ${baseTheme.colors.focus};
    }
  }

  .source-block {
    display: flex;
    margin-top: 8px;

    padding: 4px;

    border: 1px solid ${baseTheme.colors.secondary};
    border-radius: 4px;

    width: fit-content;

    .source-title {
      font-weight: 700;
      color: ${baseTheme.colors.secondary};
    }

    .source-count {
      font-weight: 700;
      color: ${baseTheme.colors.warning};
      padding: 0 8px 0 3px;
    }
  }

  .city-price-day {
    margin-top: 8px;

    .city-title {
      font-size: 20px;
      font-weight: 700;
      color: ${baseTheme.colors.secondary};
    }

    .city-price {
      font-size: 20px;
      font-weight: 700;
      color: ${baseTheme.colors.focus};
      padding: 0 8px 0 3px;
    }
  }

  .user-source-block {
    margin-top: 8px;
    div {
      padding: 3px;
    }
    span {
      font-weight: 700;
      color: ${baseTheme.colors.warning};
    }
  }

  .amount-block {
    display: flex;
    margin-top: 8px;

    .title {
      font-weight: 700;
      font-size: 20px;
      color: ${baseTheme.colors.primary};
      padding-right: 8px;
    }

    .closed-amount {
      font-weight: 700;
      font-size: 20px;
      color: ${baseTheme.colors.focus};
    }
  }

  .network-code {
    padding: 5px;
    font-weight: 500;
    color: ${baseTheme.colors.primary};
  }

  .network-block {
    border-bottom: 1px solid ${baseTheme.colors.secondary};
    display: grid;
    grid-template-columns: 0.4fr 1.2fr 0.4fr;
  }

  .orders {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .amount-orders {
    padding: 5px;
    color: ${baseTheme.colors.primary};
    span {
      font-weight: 700 !important;
      color: ${baseTheme.colors.focus} !important;
    }
  }

  .order {
    padding: 5px;

    span {
      color: ${baseTheme.colors.secondary} !important;
    }
  }
`;

export const TitleInfo = styled('div')`
  font-weight: 700;
  font-size: 22px;
  display: flex;
  color: ${baseTheme.colors.primary};
`;
