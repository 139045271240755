import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';
import { ReportPageState } from './types';

const initialState: ReportPageState = {
  pdfReport: {
    data: null,
    loading: false,
    error: null,
  },
  ordersReport: {
    data: null,
    loading: false,
    error: null,
  },
  ordersReportYest: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.generateReport.REQUEST]: (state) => {
    state.pdfReport.loading = true;
  },

  [actions.generateReport.SUCCESS]: (state, action) => {
    state.pdfReport.loading = false;
    state.pdfReport.data = action.payload;
  },

  [actions.generateReport.ERROR]: (state, action) => {
    state.pdfReport.loading = false;
    state.pdfReport.error = action.payload;
  },

  [actions.getReportByDate.REQUEST]: (state) => {
    state.ordersReport.loading = true;
  },

  [actions.getReportByDate.SUCCESS]: (state, action) => {
    state.ordersReport.loading = false;
    state.ordersReport.data = action.payload;
  },

  [actions.getReportByDate.ERROR]: (state, action) => {
    state.ordersReport.loading = false;
    state.ordersReport.error = action.payload;
  },

  [actions.getReportByDateYest.REQUEST]: (state) => {
    state.ordersReportYest.loading = true;
  },

  [actions.getReportByDateYest.SUCCESS]: (state, action) => {
    state.ordersReportYest.loading = false;
    state.ordersReportYest.data = action.payload;
  },

  [actions.getReportByDateYest.ERROR]: (state, action) => {
    state.ordersReportYest.loading = false;
    state.ordersReportYest.error = action.payload;
  },

  [actions.clearReport.type]: (state) => {
    state.pdfReport.data = null;
  },
});

const pdf = (state: AppState) => state.reportPageReducer.pdfReport.data;
const ordersToday = (state: AppState) =>
  state.reportPageReducer.ordersReport.data;
const ordersTodayLoading = (state: AppState) =>
  state.reportPageReducer.ordersReport.loading;
const ordersYesterday = (state: AppState) =>
  state.reportPageReducer.ordersReportYest.data;
const ordersYesterdayLoading = (state: AppState) =>
  state.reportPageReducer.ordersReportYest.loading;

const selectors = {
  pdf,
  ordersToday,
  ordersYesterday,
  ordersTodayLoading,
  ordersYesterdayLoading,
};

export { selectors };
export default reducer;
