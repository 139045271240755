import axios from 'axios';
import { getFromLocalStorage } from '../../global/helpers';

export class ReportService {
  static API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

  static getReport = async (date: string, testOrders?: boolean) => {
    const url = testOrders
      ? `${ReportService.API_BASE_URL}reports/orders/payments?date=${date}&test_orders=${testOrders}`
      : `${ReportService.API_BASE_URL}reports/orders/payments?date=${date}`;
    const response = await axios({
      method: 'get',
      url,
    });
    return response.data;
  };

  static generateReport = async (
    data: {
      from: string;
      to: string;
      network_code: string;
    },
    pdfF: boolean,
    xls: boolean
  ) => {
    const fileName = data.network_code
      ? `${data.network_code}/${Math.round(Date.now() / 1000)}`
      : `${Math.round(Date.now() / 1000)}`;
    try {
      await fetch(
        `${ReportService.API_BASE_URL}reports/orders/${pdfF ? 'pdf' : 'xls'}`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${getFromLocalStorage('accessToken')}`,
          },
        }
      )
        .then((resp) => {
          if (resp.status !== 200) {
            window.alert('Error');
            return Promise.reject();
          }
          return resp.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => console.error(err));
      return {};
    } catch (err) {
      throw err;
    }
  };
}
